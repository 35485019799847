import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  url: string = 'https://api.cmdtruck.ro/clients';
  
  constructor(private http: HttpClient) { }

  getClients(): Observable<Client[]> {
    return this.http.get<Client[]>(this.url);
  }

  newClient(c: any) {
    let cl = new Client(c.formNewClientType, c.formNewClientName, c.formNewClientPhone, c.formNewClientEmail, c.formNewClientLocation,
      c.formNewClientCNP, c.formNewClientCUI, c.formNewClientCIF, c.formNewClientRepresentative);
    return this.http.post<Client>(this.url, cl);
  }

// import { from } from 'rxjs';
// import { map, filter, first } from 'rxjs/operators';
//  async getClient(id) {
  
//     return await this.getClients()
//     .pipe(
//       map(client => {
//         let c = client.filter(cl => cl.id === id);
//         return (c.length > 0) ? c[0] : null;
//     })).toPromise() as Client;
//   }

  getClient(id) {
    return this.http.get<Client>(this.url + `/${id}`);
  }

}

export class Client {
  id: number;
  type: string;
  name: string;
  cnp: string;
  cui: string;
  cif: string;
  phone: string;
  email: string;
  location: string;
  representative: string;

  constructor(type: string, name: string, phone: string, email: string, location: string, cnp?: string, cui?: string, cif?: string, representative?: string) {
    this.type = type;
    this.name = name;
    this.cnp = cnp;
    this.phone = phone;
    this.email = email;
    this.location = location;
    this.cui = cui;
    this.cif = cif;
    this.representative = representative;
  }
}