import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScreenManagerService {

  private static screen;

  static setScreen(s) { 
    this.screen = s;
    localStorage.setItem('screen', this.screen);
   }

  static getScreen() { 
    if (localStorage.getItem('screen') === null) this.setScreen(0);
    return parseInt(localStorage.getItem('screen'));
  }
  
}
