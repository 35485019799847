 <!-- Masthead-->
 <header>
    <div class="container h-100">
        <div class="row h-100">
            <div class="col-lg-10 align-self-end">
                <h1 class="text-uppercase text-white font-weight-bold">Conectare</h1>
            </div>
        </div>
    </div>
</header>

<!-- Login -->
<section class="page-section" id="login">
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="username">Utilizator:</label>
                        <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required">Câmpul este obligatoriu</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="password">Parolă:</label>
                        <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Câmpul este obligatoriu</div>
                        </div>
                    </div>
                    <div class="text-center">
                        <button [disabled]="loading" class="btn btn-primary">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Conectare
                        </button>
                    </div>
                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                </form>
            </div>
        </div>
    </div>
</section>