import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from './_services';
import { User } from './_models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TablesService {

  currentUser: User;

  URL_CONCLUDED_CONTRACTS: string = "https://api.cmdtruck.ro/concluded-contracts";
  URL_POTENTIAL_CUSTOMERS: string = "https://api.cmdtruck.ro/potentialCustomers";
  URL_CONTRACTS_ORDERS_PROFIT: string = "https://api.cmdtruck.ro/contracts-orders-profit";
  URL_COMPANY_PROFIT: string = "https://api.cmdtruck.ro/profit";
  URL_INSURANCE: string = "https://api.cmdtruck.ro/insurance";

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) { 
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  // Concluded contracts

  getConcludedContracts(): Observable<ConcludedContracts[]> {
    return this.http.get<ConcludedContracts[]>(this.URL_CONCLUDED_CONTRACTS + `/all/${this.currentUser.id}`);
  }

  newConcludedContracts(c: any) {
    let contract = new ConcludedContracts(c.formConcludedContractsName, c.formConcludedContractsCUIxCNP,
      c.formConcludedContractsContactPerson, c.formConcludedContractsPhone, c.formConcludedContractsEmail, c.formConcludedContractsDateOfVisit, 
      c.formConcludedContractsVisitDetails, c.formConcludedContractsNextVisitDate, this.currentUser.id);

      return this.http.post<ConcludedContracts>(this.URL_CONCLUDED_CONTRACTS, contract);
  }

  editConcludedContracts(id, c: any) {
    let contract = new ConcludedContracts(c.formConcludedContractsName, c.formConcludedContractsCUIxCNP,
      c.formConcludedContractsContactPerson, c.formConcludedContractsPhone, c.formConcludedContractsEmail, c.formConcludedContractsDateOfVisit, 
      c.formConcludedContractsVisitDetails, c.formConcludedContractsNextVisitDate, this.currentUser.id);

      return this.http.patch<ConcludedContracts>(this.URL_CONCLUDED_CONTRACTS + `/${id}`, contract);
  }

  deleteConcludedContracts(id) {
    return this.http.delete<ConcludedContracts>(this.URL_CONCLUDED_CONTRACTS + `/${id}`);
  }

  // Potential customers

  getPotentialCustomers(): Observable<PotentialCustomers[]> {
    return this.http.get<PotentialCustomers[]>(this.URL_POTENTIAL_CUSTOMERS + `/all/${this.currentUser.id}`);
  }

  newPotentialCustomers(c: any) {
    let client = new PotentialCustomers(c.formPotentialCustomersName, c.formPotentialCustomersCUIxCNP,
      c.formPotentialCustomersContactPerson, c.formPotentialCustomersPhone, c.formPotentialCustomersEmail, c.formPotentialCustomersDateOfVisit, 
      c.formPotentialCustomersVisitDetails, c.formPotentialCustomersNextVisitDate, this.currentUser.id);

      return this.http.post<PotentialCustomers>(this.URL_POTENTIAL_CUSTOMERS, client);
  }

  editPotentialCustomers(id, c: any) {
    let client = new PotentialCustomers(c.formPotentialCustomersName, c.formPotentialCustomersCUIxCNP,
      c.formPotentialCustomersContactPerson, c.formPotentialCustomersPhone, c.formPotentialCustomersEmail, c.formPotentialCustomersDateOfVisit, 
      c.formPotentialCustomersVisitDetails, c.formPotentialCustomersNextVisitDate, this.currentUser.id);

      return this.http.patch<PotentialCustomers>(this.URL_POTENTIAL_CUSTOMERS + `/${id}`, client);
  }

  deletePotentialCustomers(id) {
    return this.http.delete<PotentialCustomers>(this.URL_POTENTIAL_CUSTOMERS + `/${id}`);
  }

  // Contracts Orders Profit

  getContractsOrdersProfit(): Observable<ContractsOrdersProfit[]> {
    return this.http.get<ContractsOrdersProfit[]>(this.URL_CONTRACTS_ORDERS_PROFIT + `/all/${this.currentUser.id}`);
  }

  newContractsOrdersProfit(c: any) {
    let cop = new ContractsOrdersProfit(c.formContractsOrdersProfitName, c.formContractsOrdersProfitTransporter, c.formContractsOrdersProfitPrice,
      c.formContractsOrdersProfitTransporterPrice, c.formContractsOrdersProfitProfit, c.formContractsOrdersProfitUploadDate,
      c.formContractsOrdersProfitDownloadDate, c.formContractsOrdersProfitUploadStatus, c.formContractsOrdersProfitDownloadStatus,
      c.formContractsOrdersProfitInvoiceNo, c.formContractsOrdersProfitInvoiceNoTransporter, c.formContractsOrdersProfitPaymentTransporter,
      c.formContractsOrdersProfitPaymentClient, c.formContractsOrdersProfitDocStatusTransporter, c.formContractsOrdersProfitDateSendDocToClient, this.currentUser.id);
      
    return this.http.post<ContractsOrdersProfit>(this.URL_CONTRACTS_ORDERS_PROFIT, cop);
  }

  editContractsOrdersProfit(id, c: any) {
    let cop = new ContractsOrdersProfit(c.formContractsOrdersProfitName, c.formContractsOrdersProfitTransporter, c.formContractsOrdersProfitPrice,
      c.formContractsOrdersProfitTransporterPrice, c.formContractsOrdersProfitProfit, c.formContractsOrdersProfitUploadDate,
      c.formContractsOrdersProfitDownloadDate, c.formContractsOrdersProfitUploadStatus, c.formContractsOrdersProfitDownloadStatus,
      c.formContractsOrdersProfitInvoiceNo, c.formContractsOrdersProfitInvoiceNoTransporter, c.formContractsOrdersProfitPaymentTransporter,
      c.formContractsOrdersProfitPaymentClient, c.formContractsOrdersProfitDocStatusTransporter, c.formContractsOrdersProfitDateSendDocToClient, this.currentUser.id);

    return this.http.patch<ContractsOrdersProfit>(this.URL_CONTRACTS_ORDERS_PROFIT + `/${id}`, cop);
  }

  deleteContractsOrdersProfit(id) {
    return this.http.delete<ContractsOrdersProfit>(this.URL_CONTRACTS_ORDERS_PROFIT + `/${id}`);
  }

  // Company profit

  getCompanyProfit(): Observable<CompanyProfitArr[]> {
    return this.http.get<CompanyProfitArr[]>(this.URL_COMPANY_PROFIT + `/all/${this.currentUser.id}`);
  }

  newCompanyProfit(p: any) {
    let profit = new CompanyProfit(p.formCompanyProfitDay, p.formCompanyProfitMonth, p.formCompanyProfitYear,
      p.formCompanyProfitRON, p.formCompanyProfitEURO, this.currentUser.id);

      return this.http.post<CompanyProfit>(this.URL_COMPANY_PROFIT, profit);
  }

  editCompanyProfit(p: any) {
    let profit = new CompanyProfit(p.formCompanyProfitDay, p.formCompanyProfitMonth, p.formCompanyProfitYear,
      p.formCompanyProfitRON, p.formCompanyProfitEURO, this.currentUser.id);

      return this.http.patch<CompanyProfit>(this.URL_COMPANY_PROFIT + `/edit`, profit);
  }

  deleteCompanyProfit(id) {
    return this.http.delete<CompanyProfit>(this.URL_COMPANY_PROFIT + `/${id}`);
  }

  // Insurance

  getInsurance(): Observable<Insurance[]> {
    return this.http.get<Insurance[]>(this.URL_INSURANCE + `/all/${this.currentUser.id}`);
  }

  newInsurance(i: any) {
    let insurance = new Insurance(i.formInsuranceTransporter, i.formInsuranceCarNumber, i.formInsuranceSemitrailer, i.formInsuranceStartDate,
      i.formInsuranceLastDate, i.formInsuranceEndDate, i.formInsuranceInsuranceValue, this.currentUser.id)

      return this.http.post<Insurance>(this.URL_INSURANCE, insurance);
  }

  editInsurance(id, i: any) {
    let insurance = new Insurance(i.formInsuranceTransporter, i.formInsuranceCarNumber, i.formInsuranceSemitrailer, i.formInsuranceStartDate,
      i.formInsuranceLastDate, i.formInsuranceEndDate, i.formInsuranceInsuranceValue, this.currentUser.id)

      return this.http.patch<Insurance>(this.URL_INSURANCE + `/${id}`, insurance);
  }

  deleteInsurance(id) {
    return this.http.delete<Insurance>(this.URL_INSURANCE + `/${id}`);
  }

}

export class ConcludedContracts {
  id: number;
  name: string;
  CUI_CNP: string;
  contactPerson: string;
  phone: string;
  email: string;
  dateOfVisit: string;
  visitDetails: string;
  nextVisitDate: string;
  userId: number;

  constructor(name: string, CUI_CNP: string, contactPerson: string, phone: string, email: string, dateOfVisit: string, visitDetails: string, nextVisitDate: string, userId: number) {
    this.name = name;
    this.CUI_CNP = CUI_CNP;
    this.contactPerson = contactPerson;
    this.phone = phone;
    this.email = email;
    this.dateOfVisit = dateOfVisit;
    this.visitDetails = visitDetails;
    this.nextVisitDate = nextVisitDate;
    this.userId = userId;
  }
}

export class PotentialCustomers {
  id: number;
  name: string;
  CUI_CNP: string;
  contactPerson: string;
  phone: string;
  email: string;
  dateOfVisit: string;
  visitDetails: string;
  nextVisitDate: string;
  userId: number;

  constructor(name: string, CUI_CNP: string, contactPerson: string, phone: string, email: string, dateOfVisit: string, visitDetails: string, nextVisitDate: string, userId: number) {
    this.name = name;
    this.CUI_CNP = CUI_CNP;
    this.contactPerson = contactPerson;
    this.phone = phone;
    this.email = email;
    this.dateOfVisit = dateOfVisit;
    this.visitDetails = visitDetails;
    this.nextVisitDate = nextVisitDate;
    this.userId = userId;
  }
}

export class ContractsOrdersProfit {
  id: number;
  name: string;
  transporter: string;
  price: string;
  transporterPrice: string;
  profit: string;
  uploadDate: string;
  downloadDate: string;
  uploadStatus: string;
  downloadStatus: string;
  invoiceNo: string;
  invoiceNoTransporter: string;
  paymentTransporter: string;
  paymentClient: string;
  docStatusTransporter: string;
  dateSendDocToClient: string;
  userId: number;					

  constructor(name: string, transporter: string, price: string, transporterPrice: string, profit: string, uploadDate: string, downloadDate: string, uploadStatus: string, downloadStatus: string, invoiceNo: string, invoiceNoTransporter: string, paymentTransporter: string, paymentClient: string, docStatusTransporter: string, dateSendDocToClient: string, userId: number) {
    this.name = name;
    this.transporter = transporter;
    this.price = price;
    this.transporterPrice = transporterPrice;
    this.profit = profit;
    this.uploadDate = uploadDate;
    this.downloadDate = downloadDate;
    this.uploadStatus = uploadStatus;
    this.downloadStatus = downloadStatus;
    this.invoiceNo = invoiceNo;
    this.invoiceNoTransporter = invoiceNoTransporter;
    this.paymentTransporter = paymentTransporter;
    this.paymentClient = paymentClient;
    this.docStatusTransporter = docStatusTransporter;
    this.dateSendDocToClient = dateSendDocToClient;
    this.userId = userId;		
  }
}

export class CompanyProfit {
  id: number;
  day: number;
  month: number;
  year: number;
  RON: number;
  EURO: number;
  userId: number;

  constructor(day, month, year, RON, EURO, userId) {
    this.day = day;
    this.month = month;
    this.year = year;
    this.RON = RON;
    this.EURO = EURO;
    this.userId = userId;
  }
}

export class CompanyProfitArr {
  id: number;
  month: number;
  year: number;
  TotalRON: number;
  TotalEURO: number;
  days: string;
  RON: string;
  EURO: string;
}

export class Insurance {
  id: number;
  transporter: string;
  carNumber: string;
  semitrailer: string;
  startDate: string;
  lastDate: string;
  endDate: string;
  insuranceValue: string;
  userId: number;

  constructor(transporter: string, carNumber: string, semitrailer: string, startDate: string, lastDate: string, endDate: string, insuranceValue: string, userId: number) {
    this.transporter = transporter;
    this.carNumber = carNumber;
    this.semitrailer = semitrailer;
    this.startDate = startDate;
    this.lastDate = lastDate;
    this.endDate = endDate;
    this.insuranceValue = insuranceValue;
    this.userId = userId;
  }
}