import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  location: Location;

  constructor(private router: Router) { }

  ngOnInit() {
    if (environment.production) {
      // if (location.protocol === 'http:') {
      //   window.location.href = location.href.replace('http', 'https');
      // }
    }
    
      this.router.events.subscribe((evt) => {
          if (!(evt instanceof NavigationEnd)) {
              return;
          }
          window.scrollTo(0, 0)
      });
  }
 
}
