 <!-- Masthead-->
 <header>
    <div class="container h-100">
        <div class="row h-100">
            <div class="col-lg-10 align-self-end">
                <h1 class="text-uppercase text-white font-weight-bold">Istoric facturi</h1>
            </div>
        </div>
    </div>
</header>

<!-- History -->
<section class="page-section" id="history">
    <div class="container">
      <div class="row mb-5 text-center">
        <div class="col">
          <div class="btn-group" role="group">
            <button type="button" class="btn btn-outline-primary" [ngClass]="tableView === 1? 'active' : ''" (click)="loadConcludedContracts()">Contracte incheiate</button>
            <button type="button" class="btn btn-outline-primary" [ngClass]="tableView === 2? 'active' : ''"  (click)="loadPotentialCustomers()">Clienti potentiali</button>
            <button type="button" class="btn btn-outline-primary" [ngClass]="tableView === 3? 'active' : ''"  (click)="loadContractsOrdersProfit()">Contracte+Comenzi+Profit</button>
            <button type="button" class="btn btn-outline-primary" [ngClass]="tableView === 4? 'active' : ''"  (click)="loadCompanyProfit()">Profit firma</button>
            <button type="button" class="btn btn-outline-primary" [ngClass]="tableView === 5? 'active' : ''"  (click)="loadInsurance()">Asigurari</button>
          </div>
        </div>
      </div>

      <!-- Concluded Contracts View -->
      <div id="concluded-contracts" [hidden]="tableView!==1">

        <mat-form-field>
          <mat-label>Căutare</mat-label>
          <input matInput (keyup)="filterConcludedContracts($event)" placeholder="Ex. nume, CNP, CUI" #input>
        </mat-form-field>
  
        <div class="mat-elevation-z8">
  
          <table mat-table [dataSource]="dataSourceConcludedContracts" matSort multiTemplateDataRows>
  
            <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsKey">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header> {{columnsName[column]}} </th>
              <td mat-cell *matCellDef="let element"> 
                {{column !== 'actions'? element[column] : ''}} 
                <div *ngIf="column === 'actions'">
                  <button mat-icon-button color="accent" data-toggle="modal" data-target="#concludedContractsModal" (click)="$event.stopPropagation();openConcludedContractsModal()">
                    <mat-icon aria-label="Add">add</mat-icon>
                  </button>
                  <button mat-icon-button color="accent" (click)="$event.stopPropagation(); editConcludedContracts(element.id, element.name, element.CUI_CNP, element.phone, element.email, element.dateOfVisit, element.nextVisitDate, element.contactPerson, element.visitDetails)">
                    <mat-icon aria-label="Edit">edit</mat-icon>
                  </button>
                  <button mat-icon-button color="accent" (click)="$event.stopPropagation(); deleteConcludedContracts(element.id)">
                    <mat-icon aria-label="Delete">delete</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>
          
            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="columnsKey.length">
                <div class="element-detail"
                     [@detailExpand]="element == expandedElementConcludedContracts ? 'expanded' : 'collapsed'">
                  <div class="element-description">
                    <p>Telefon: {{element.phone}}</p>
                    <p>Email: {{element.email}}</p>
                    <p>Detalii vizită: {{element.visitDetails}}</p>
                    <p>Data următoarei vizite: {{element.nextVisitDate}}</p>
                  </div>
                </div>
              </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="columnsKey"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsKey;"
                class="element-row"
                [class.expanded-row]="expandedElementConcludedContracts === element"
                (click)="expandedElementConcludedContracts = expandedElementConcludedContracts === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
  
              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6" *ngIf="input.value !== ''">Căutarea dvs. "{{input.value}}" nu a returnat niciun rezultat.</td>
                <td class="mat-cell" colspan="5" *ngIf="input.value === ''">
                  Nu aveți date salvate...
                  <button mat-icon-button color="accent" class="float-right" data-toggle="modal" data-target="#concludedContractsModal" (click)="$event.stopPropagation();openConcludedContractsModal()">
                    <mat-icon aria-label="Add">add</mat-icon> Adaugati date noi
                  </button>
                </td>
              </tr>
            </table>
  
            <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
        </div>

        <!-- Modal form -->
        <div class="modal fade" id="concludedContractsModal" tabindex="-1" role="dialog" aria-labelledby="concludedContractsModalLabel" aria-hidden="true" data-backdrop="static">
          <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">
              <div class="modal-header text-center">
              <h5 class="modal-title w-100" id="concludedContractsModalLabel"> {{stateModalConcludedContracts === "add"? 'Adauga un contract nou' : 'Editeaza un contract existent'}} </h5>
              <a type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </a>
              </div>
              <form [formGroup]="formConcludedContracts" (ngSubmit)="addOrEditConcludedContracts()">
                  <div class="modal-body">                
                      <div class="row mt-3">
                          <div class="col-lg-6">
                              <input type="text" placeholder="Nume" class="form-control" formControlName="formConcludedContractsName" required>
                          </div>
                          <div class="col-lg-6">
                              <input type="text" placeholder="CUI sau CNP" class="form-control" formControlName="formConcludedContractsCUIxCNP" required>
                          </div>
                      </div>     
                      
                      <div class="row">
                          <div class="col-lg-6">
                              <input type="text" placeholder="Telefon" class="form-control" formControlName="formConcludedContractsPhone" required>
                          </div>
                          <div class="col-lg-6">
                              <input type="text" placeholder="Email" class="form-control" formControlName="formConcludedContractsEmail" required>
                          </div>
                      </div>  

                      <div class="row">
                          <div class="col-lg-6">
                              <input type="text" placeholder="Dată vizită" class="form-control" formControlName="formConcludedContractsDateOfVisit" required>
                          </div>
                          <div class="col-lg-6">
                              <input type="text" placeholder="Data următoarei vizite" class="form-control" formControlName="formConcludedContractsNextVisitDate">
                          </div>
                      </div>  
                      
                      <div class="row">
                          <div class="col-lg">
                              <input type="text" placeholder="Persoană de contact" class="form-control" formControlName="formConcludedContractsContactPerson" required>
                          </div>
                      </div>  

                      <div class="row">
                          <div class="col-lg">
                              <input type="text" class="form-control" formControlName="formConcludedContractsVisitDetails" required>
                          </div>
                      </div>  
                  </div>
                  <div class="modal-footer">
                  <button type="button" data-dismiss="modal">Inchide</button>
                  <button type="submit" name="submit" value="New Client">Salveaza contractul</button>
                  </div>
              </form>
          </div>
          </div>
        </div>

      </div>
      
      <!-- Potential Customers View -->
      <div id="potential-customers" [hidden]="tableView!==2">
        
        <mat-form-field>
          <mat-label>Căutare</mat-label>
          <input matInput (keyup)="filterPotentialCustomers($event)" placeholder="Ex. nume, CNP, CUI" #input>
        </mat-form-field>
  
        <div class="mat-elevation-z8">
  
          <table mat-table [dataSource]="dataSourcePotentialCustomers" matSort multiTemplateDataRows>
  
            <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsKey">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header> {{columnsName[column]}} </th>
              <td mat-cell *matCellDef="let element"> 
                {{column !== 'actions'? element[column] : ''}} 
                <div *ngIf="column === 'actions'">
                  <button mat-icon-button color="accent" data-toggle="modal" data-target="#potentialCustomersModal" (click)="$event.stopPropagation();openPotentialCustomersModal()">
                    <mat-icon aria-label="Add">add</mat-icon>
                  </button>
                  <button mat-icon-button color="accent" (click)="$event.stopPropagation(); editPotentialCustomers(element.id, element.name, element.CUI_CNP, element.phone, element.email, element.dateOfVisit, element.nextVisitDate, element.contactPerson, element.visitDetails)">
                    <mat-icon aria-label="Edit">edit</mat-icon>
                  </button>
                  <button mat-icon-button color="accent" (click)="$event.stopPropagation(); deletePotentialCustomers(element.id)">
                    <mat-icon aria-label="Delete">delete</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>
          
            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="columnsKey.length">
                <div class="element-detail"
                     [@detailExpand]="element == expandedElementPotentialCustomers ? 'expanded' : 'collapsed'">
                  <div class="element-description">
                    <p>Telefon: {{element.phone}}</p>
                    <p>Email: {{element.email}}</p>
                    <p>Detalii vizită: {{element.visitDetails}}</p>
                    <p>Data următoarei vizite: {{element.nextVisitDate}}</p>
                  </div>
                </div>
              </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="columnsKey"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsKey;"
                class="element-row"
                [class.expanded-row]="expandedElementPotentialCustomers === element"
                (click)="expandedElementPotentialCustomers = expandedElementPotentialCustomers === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
  
              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6" *ngIf="input.value !== ''">Căutarea dvs. "{{input.value}}" nu a returnat niciun rezultat.</td>
                <td class="mat-cell" colspan="5" *ngIf="input.value === ''">
                  Nu aveți date salvate...
                  <button mat-icon-button color="accent" class="float-right" data-toggle="modal" data-target="#potentialCustomersModal" (click)="$event.stopPropagation();openPotentialCustomersModal()">
                    <mat-icon aria-label="Add">add</mat-icon> Adaugati date noi
                  </button>
                </td>
              </tr>
            </table>
  
            <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
        </div>

        <!-- Modal form -->
        <div class="modal fade" id="potentialCustomersModal" tabindex="-1" role="dialog" aria-labelledby="potentialCustomersModalLabel" aria-hidden="true" data-backdrop="static">
          <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">
              <div class="modal-header text-center">
              <h5 class="modal-title w-100" id="potentialCustomersModalLabel"> {{stateModalPotentialCustomers === "add"? 'Adauga un nou potential client' : 'Editeaza un client potential existent'}} </h5>
              <a type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </a>
              </div>
              <form [formGroup]="formPotentialCustomers" (ngSubmit)="addOrEditPotentialCustomers()">
                  <div class="modal-body">                
                      <div class="row mt-3">
                          <div class="col-lg-6">
                              <input type="text" placeholder="Nume" class="form-control" formControlName="formPotentialCustomersName" required>
                          </div>
                          <div class="col-lg-6">
                              <input type="text" placeholder="CUI sau CNP" class="form-control" formControlName="formPotentialCustomersCUIxCNP" required>
                          </div>
                      </div>     
                      
                      <div class="row">
                          <div class="col-lg-6">
                              <input type="text" placeholder="Telefon" class="form-control" formControlName="formPotentialCustomersPhone" required>
                          </div>
                          <div class="col-lg-6">
                              <input type="text" placeholder="Email" class="form-control" formControlName="formPotentialCustomersEmail" required>
                          </div>
                      </div>  

                      <div class="row">
                          <div class="col-lg-6">
                              <input type="text" placeholder="Dată vizită" class="form-control" formControlName="formPotentialCustomersDateOfVisit" required>
                          </div>
                          <div class="col-lg-6">
                              <input type="text" placeholder="Data următoarei vizite" class="form-control" formControlName="formPotentialCustomersNextVisitDate">
                          </div>
                      </div>  
                      
                      <div class="row">
                          <div class="col-lg">
                              <input type="text" placeholder="Persoană de contact" class="form-control" formControlName="formPotentialCustomersContactPerson" required>
                          </div>
                      </div>  

                      <div class="row">
                          <div class="col-lg">
                              <input type="text" class="form-control" formControlName="formPotentialCustomersVisitDetails" required>
                          </div>
                      </div>  
                  </div>
                  <div class="modal-footer">
                  <button type="button" data-dismiss="modal">Inchide</button>
                  <button type="submit" name="submit" value="New Client">Salveaza clientul</button>
                  </div>
              </form>
          </div>
          </div>
        </div>

      </div>

      <!-- Contracts Orders Profit View -->
      <div id="contracts-orders-profit" [hidden]="tableView!==3">
        
        <mat-form-field>
          <mat-label>Căutare</mat-label>
          <input matInput (keyup)="filterContractsOrdersProfit($event)" placeholder="Ex. nume client / transportator, profit, status" #input>
        </mat-form-field>
  
        <div class="mat-elevation-z8">
  
          <table mat-table [dataSource]="dataSourceContractsOrdersProfit" matSort multiTemplateDataRows>
  
            <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsKey">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header> {{columnsName[column]}} </th>
              <td mat-cell *matCellDef="let element"> 
                {{column !== 'actions'? element[column] : ''}} 
                <div *ngIf="column === 'actions'">
                  <button mat-icon-button color="accent" data-toggle="modal" data-target="#contractsOrdersProfitModal" (click)="$event.stopPropagation();openContractsOrdersProfitModal()">
                    <mat-icon aria-label="Add">add</mat-icon>
                  </button>
                  <button mat-icon-button color="accent" (click)="$event.stopPropagation(); editContractsOrdersProfit(element.id, element.name, element.transporter, element.price, element.transporterPrice, element.profit, element.uploadDate, element.downloadDate, element.uploadStatus, element.downloadStatus, element.invoiceNo, element.invoiceNoTransporter, element.paymentTransporter, element.paymentClient, element.docStatusTransporter, element.dateSendDocToClient)">
                    <mat-icon aria-label="Edit">edit</mat-icon>
                  </button>
                  <button mat-icon-button color="accent" (click)="$event.stopPropagation(); deleteContractsOrdersProfit(element.id)">
                    <mat-icon aria-label="Delete">delete</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>
          
            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="columnsKey.length">
                <div class="element-detail"
                     [@detailExpand]="element == expandedElementContractsOrdersProfit ? 'expanded' : 'collapsed'">
                  <div class="element-description">
                    <p>Pret firma client: {{element.price}}</p>
                    <p>Pret transportator: {{element.transporterPrice}}</p>
                    <p>Data descarcare: {{element.downloadDate}}</p>
                    <p>Status incarcare: {{element.uploadStatus}}</p>
                    <p>Status descarcare: {{element.downloadStatus}}</p>
                    <p>Nr factura client: {{element.invoiceNo}}</p>
                    <p>Nr factura transportator: {{element.invoiceNoTransporter}}</p>
                    <p>Plata client: {{element.paymentClient}}</p>
                    <p>Plata transportator: {{element.paymentTransporter}}</p>
                    <p>Status primire documente transportator: {{element.docStatusTransporter}}</p>
                    <p>Data trimitere documente client: {{element.dateSendDocToClient}}</p>
                  </div>
                </div>
              </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="columnsKey"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsKey;"
                class="element-row"
                [class.expanded-row]="expandedElementContractsOrdersProfit === element"
                (click)="expandedElementContractsOrdersProfit = expandedElementContractsOrdersProfit === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
  
              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6" *ngIf="input.value !== ''">Căutarea dvs. "{{input.value}}" nu a returnat niciun rezultat.</td>
                <td class="mat-cell" colspan="5" *ngIf="input.value === ''">
                  Nu aveți date salvate...
                  <button mat-icon-button color="accent" class="float-right" data-toggle="modal" data-target="#contractsOrdersProfitModal" (click)="$event.stopPropagation();openContractsOrdersProfitModal()">
                    <mat-icon aria-label="Add">add</mat-icon> Adaugati date noi
                  </button>
                </td>
              </tr>

            </table>
  
            <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
        </div>

        <!-- Modal form -->
        <div class="modal fade" id="contractsOrdersProfitModal" tabindex="-1" role="dialog" aria-labelledby="contractsOrdersProfitModalLabel" aria-hidden="true" data-backdrop="static">
          <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">
              <div class="modal-header text-center">
              <h5 class="modal-title w-100" id="contractsOrdersProfitModalLabel"> {{stateModalContractsOrdersProfit === "add"? 'Adauga contract+comanda+profit' : 'Editeaza un contract+comanda+profit existent'}} </h5>
              <a type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </a>
              </div>
              <form [formGroup]="formContractsOrdersProfit" (ngSubmit)="addOrEditContractsOrdersProfit()">
                  <div class="modal-body">                
                      <div class="row mt-3">
                          <div class="col-lg-6">
                              <input type="text" placeholder="Nume firma client" class="form-control" formControlName="formContractsOrdersProfitName" required>
                          </div>
                          <div class="col-lg-6">
                              <input type="text" placeholder="Nume transporator" class="form-control" formControlName="formContractsOrdersProfitTransporter" required>
                          </div>
                      </div>     
                      
                      <div class="row">
                          <div class="col-lg-4">
                              <input type="text" placeholder="Pret firma client" class="form-control" formControlName="formContractsOrdersProfitPrice" required>
                          </div>
                          <div class="col-lg-4">
                              <input type="text" placeholder="Pret transporator" class="form-control" formControlName="formContractsOrdersProfitTransporterPrice" required>
                          </div>
                          <div class="col-lg-4">
                            <input type="text" placeholder="Profit" class="form-control" formControlName="formContractsOrdersProfitProfit" required>
                        </div>
                      </div>  

                      <div class="row">
                          <div class="col-lg-6">
                              <input type="text" placeholder="Dată incarcare" class="form-control" formControlName="formContractsOrdersProfitUploadDate" required>
                          </div>
                          <div class="col-lg-6">
                              <input type="text" placeholder="Dată descarcare" class="form-control" formControlName="formContractsOrdersProfitDownloadDate" required>
                          </div>
                      </div>  

                      <div class="row">
                        <div class="col-lg-6">
                            <input type="text" placeholder="Status incarcare" class="form-control" formControlName="formContractsOrdersProfitUploadStatus">
                        </div>
                        <div class="col-lg-6">
                            <input type="text" placeholder="Status descarcare" class="form-control" formControlName="formContractsOrdersProfitDownloadStatus">
                        </div>
                    </div>  

                    <div class="row">
                      <div class="col-lg-6">
                          <input type="text" placeholder="Nr. factura client" class="form-control" formControlName="formContractsOrdersProfitInvoiceNo">
                      </div>
                      <div class="col-lg-6">
                          <input type="text" placeholder="Nr. factura transporator" class="form-control" formControlName="formContractsOrdersProfitInvoiceNoTransporter">
                      </div>
                  </div>  

                  <div class="row">
                    <div class="col-lg-6">
                        <input type="text" placeholder="Plata transportator" class="form-control" formControlName="formContractsOrdersProfitPaymentTransporter" required>
                    </div>
                    <div class="col-lg-6">
                        <input type="text" placeholder="Plata client" class="form-control" formControlName="formContractsOrdersProfitPaymentClient" required>
                    </div>
                </div>  

                <div class="row">
                  <div class="col-lg-6">
                      <input type="text" placeholder="Status primire documente transporator" class="form-control" formControlName="formContractsOrdersProfitDocStatusTransporter">
                  </div>
                  <div class="col-lg-6">
                      <input type="text" placeholder="Dată trimitere documente client" class="form-control" formControlName="formContractsOrdersProfitDateSendDocToClient">
                  </div>
              </div>                    
                  </div>
                  <div class="modal-footer">
                  <button type="button" data-dismiss="modal">Inchide</button>
                  <button type="submit" name="submit" value="New Client">Salveaza datele</button>
                  </div>
              </form>
          </div>
          </div>
        </div>

      </div>

      <!-- Company Profit View -->
      <div id="company-profit" [hidden]="tableView!==4">
        
        <mat-form-field>
          <mat-label>Căutare</mat-label>
          <input matInput (keyup)="filterCompanyProfit($event)" placeholder="Ex. zi, luna, an, profit" #input>
        </mat-form-field>
  
        <div class="mat-elevation-z8">
  
          <table mat-table [dataSource]="dataSourceCompanyProfit" matSort multiTemplateDataRows>
  
            <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsKey">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header> {{columnsName[column]}} </th>
              <td mat-cell *matCellDef="let element"> 
                {{column !== 'actions'? element[column] : ''}} 
                <div *ngIf="column === 'actions'">
                  <button mat-icon-button color="accent" data-toggle="modal" data-target="#companyProfitModal" (click)="$event.stopPropagation();openCompanyProfitModal()">
                    <mat-icon aria-label="Add">add</mat-icon>
                  </button>
                  <button mat-icon-button color="accent" (click)="$event.stopPropagation(); editCompanyProfit(element.month, element.year)">
                    <mat-icon aria-label="Edit">edit</mat-icon>
                  </button>
                  <button mat-icon-button color="accent" (click)="$event.stopPropagation(); deleteCompanyProfit(element.id)">
                    <mat-icon aria-label="Delete">delete</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>
          
            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="columnsKey.length">
                <div class="element-detail"
                     [@detailExpand]="element == expandedElementCompanyProfit ? 'expanded' : 'collapsed'">
                  <div class="element-description">
                    <p *ngFor="let day of element.days.split(','); index as i">
                      Zi: {{day}}
                      <br/>
                      Profit RON: {{element.RON.split(',')[i]}}
                      <br/>
                      Profit EURO: {{element.EURO.split(',')[i]}}
                    </p>
                  </div>
                </div>
              </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="columnsKey"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsKey;"
                class="element-row"
                [class.expanded-row]="expandedElementCompanyProfit === element"
                (click)="expandedElementCompanyProfit = expandedElementCompanyProfit === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
  
              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6" *ngIf="input.value !== ''">Căutarea dvs. "{{input.value}}" nu a returnat niciun rezultat.</td>
                <td class="mat-cell" colspan="5" *ngIf="input.value === ''">
                  Nu aveți date salvate...
                  <button mat-icon-button color="accent" class="float-right" data-toggle="modal" data-target="#companyProfitModal" (click)="$event.stopPropagation();openCompanyProfitModal()">
                    <mat-icon aria-label="Add">add</mat-icon> Adaugati date noi
                  </button>
                </td>
              </tr>
            </table>
  
            <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
        </div>

        <!-- Modal form -->
<div class="modal fade" id="companyProfitModal" tabindex="-1" role="dialog" aria-labelledby="companyProfitModalLabel" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-xl" role="document">
  <div class="modal-content">
      <div class="modal-header text-center">
      <h5 class="modal-title w-100" id="companyProfitModalLabel"> {{stateModalCompanyProfit === "add"? 'Adauga profit' : 'Editeaza profit'}} </h5>
      <a type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
      </a>
      </div>
      <form [formGroup]="formCompanyProfit" (ngSubmit)="addOrEditCompanyProfit()">
          <div class="modal-body">                
              <div class="row mt-3">
                  <div class="col-lg-4">
                      <input type="text" placeholder="Zi" class="form-control" formControlName="formCompanyProfitDay" required>
                  </div>
                  <div class="col-lg-4">
                      <input type="text" placeholder="Luna" class="form-control" formControlName="formCompanyProfitMonth" required>
                  </div>
                  <div class="col-lg-4">
                    <input type="text" placeholder="An" class="form-control" formControlName="formCompanyProfitYear" required>
                </div>
              </div>     

              <div class="row">
                  <div class="col-lg-6">
                      <input type="text" placeholder="Profit in RON" class="form-control" formControlName="formCompanyProfitRON" required>
                  </div>
                  <div class="col-lg-6">
                      <input type="text" placeholder="Profit in EURO" class="form-control" formControlName="formCompanyProfitEURO" required>
                  </div>
              </div>                   
          </div>
          <div class="modal-footer">
          <button type="button" data-dismiss="modal">Inchide</button>
          <button type="submit" name="submit" value="New Client">Salveaza datele</button>
          </div>
      </form>
    </div>
  </div>
</div>

</div>


      <!-- Insurance View -->
      <div id="insurance" [hidden]="tableView!==5">
        
        <mat-form-field>
          <mat-label>Căutare</mat-label>
          <input matInput (keyup)="filterInsurance($event)" placeholder="Ex. nume transportator, numar masina, semiremorca" #input>
        </mat-form-field>
  
        <div class="mat-elevation-z8">
  
          <table mat-table [dataSource]="dataSourceInsurance" matSort multiTemplateDataRows>
  
            <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsKey">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header> {{columnsName[column]}} </th>
              <td mat-cell *matCellDef="let element"> 
                {{column !== 'actions'? element[column] : ''}} 
                <div *ngIf="column === 'actions'">
                  <button mat-icon-button color="accent" data-toggle="modal" data-target="#insuranceModal" (click)="$event.stopPropagation();openInsuranceModal()">
                    <mat-icon aria-label="Add">add</mat-icon>
                  </button>
                  <button mat-icon-button color="accent" (click)="$event.stopPropagation(); editInsurance(element.id, element.transporter, element.carNumber, element.semitrailer, element.startDate, element.lastDate, element.endDate, element.insuranceValue)">
                    <mat-icon aria-label="Edit">edit</mat-icon>
                  </button>
                  <button mat-icon-button color="accent" (click)="$event.stopPropagation(); deleteInsurance(element.id)">
                    <mat-icon aria-label="Delete">delete</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>
          
            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="columnsKey.length">
                <div class="element-detail"
                     [@detailExpand]="element == expandedElementInsurance ? 'expanded' : 'collapsed'">
                  <div class="element-description">
                    <p>Semiremorca: {{element.semitrailer}}</p>
                    <p>Data ultima rata: {{element.lastDate}}</p>
                    <p>Data sfarsit {{element.endDate}}</p>
                  </div>
                </div>
              </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="columnsKey"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsKey;"
                class="element-row"
                [class.expanded-row]="expandedElementInsurance === element"
                (click)="expandedElementInsurance = expandedElementInsurance === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
  
              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6" *ngIf="input.value !== ''">Căutarea dvs. "{{input.value}}" nu a returnat niciun rezultat.</td>
                <td class="mat-cell" colspan="5" *ngIf="input.value === ''">
                  Nu aveți date salvate...
                  <button mat-icon-button color="accent" class="float-right" data-toggle="modal" data-target="#insuranceContractsModal" (click)="$event.stopPropagation();openInsuranceModal()">
                    <mat-icon aria-label="Add">add</mat-icon> Adaugati date noi
                  </button>
                </td>
              </tr>

            </table>
  
            <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
        </div>

        <!-- Modal form -->
        <div class="modal fade" id="insuranceModal" tabindex="-1" role="dialog" aria-labelledby="insuranceModalLabel" aria-hidden="true" data-backdrop="static">
          <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">
              <div class="modal-header text-center">
              <h5 class="modal-title w-100" id="insuranceModalLabel"> {{stateModalInsurance === "add"? 'Adauga o asigurare noua' : 'Editeaza o asigurare existent'}} </h5>
              <a type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </a>
              </div>
              <form [formGroup]="formInsurance" (ngSubmit)="addOrEditInsurance()">
                  <div class="modal-body">                
                      <div class="row mt-3">
                          <div class="col-lg-6">
                              <input type="text" placeholder="Nume transporator" class="form-control" formControlName="formInsuranceTransporter" required>
                          </div>
                          <div class="col-lg-6">
                              <input type="text" placeholder="Valoare asigurare" class="form-control" formControlName="formInsuranceInsuranceValue">
                          </div>
                      </div>     
                      
                      <div class="row">
                          <div class="col-lg-6">
                              <input type="text" placeholder="Numar masina / cap tractor, van" class="form-control" formControlName="formInsuranceCarNumber" required>
                          </div>
                          <div class="col-lg-6">
                              <input type="text" placeholder="Semiremorca" class="form-control" formControlName="formInsuranceSemitrailer">
                          </div>
                      </div>  

                      <div class="row">
                          <div class="col-lg-4">
                              <input type="text" placeholder="Dată inceput" class="form-control" formControlName="formInsuranceStartDate" required>
                          </div>
                          <div class="col-lg-4">
                              <input type="text" placeholder="Dată ultima rata" class="form-control" formControlName="formInsuranceLastDate">
                          </div>
                          <div class="col-lg-4">
                            <input type="text" placeholder="Dată sfarsit" class="form-control" formControlName="formInsuranceEndDate">
                        </div>
                      </div>   
                  </div>
                  <div class="modal-footer">
                  <button type="button" data-dismiss="modal">Inchide</button>
                  <button type="submit" name="submit" value="New Client">Salveaza asigurarea</button>
                  </div>
              </form>
          </div>
          </div>
        </div>

      </div>

    </div>
</section>