import { Routes, RouterModule } from '@angular/router';
import { InvoicesComponent } from './invoices/invoices.component';
import { HistoryComponent } from './history/history.component';
import { LoginComponent } from './login/login.component';

import { AuthGuard } from './_helpers';

const appRoutes : Routes = [
    { path : '', component: InvoicesComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'history', component: HistoryComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path : '**', redirectTo: '', pathMatch: 'full' }
];

export const routing = RouterModule.forRoot(
    appRoutes,
    {
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload',
        scrollPositionRestoration: 'enabled'
      });