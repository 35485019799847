<link href="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css" rel="stylesheet" />
<link href="/../../assets/styles/flags.css" rel="stylesheet" />

 <!-- Masthead-->
 <header>
    <div class="container h-100">
        <div class="row h-100">
            <div class="col-lg-10 align-self-end">
                <h1 class="text-uppercase text-white font-weight-bold">Factură nouă</h1>
            </div>
        </div>
    </div>
</header>

<!-- Invoices -->
<section class="page-section" id="invoices">
    <div class="container cform">
        <div class="row">
            <div class="col-lg">
                <div class="invoices-widget">
                    <h3>{{labelText}}</h3>
                    <p>{{hintText}}</p>
                    <div [ngClass]="validForm? 'd-none' : 'd-block'" class="alert alert-danger mt-4 fade-in" role="alert">
                        {{errorMessage}}
                    </div>
                    <div [hidden]="isScreenFrozen === false">
                        <button name="reset" value="Reset screen" (click)="resetScreen()">Resetare ecran</button>
                    </div>
                    <form name="selectForm" (ngSubmit)="mainForm()" [hidden]="typeContract!==0">
                        <div class="row mt-5">
                            <div class="col-sm-4">
                                <label>Tipul de contract:</label>
                            </div>
                            <div class="col-sm-8">
                                <select name="selectFormContract">
                                    <option></option>
                                    <option value="1">ACT ADITIONAL - CLIENT</option>
                                    <option value="2">ACT ADITIONAL - TRANSPORTATOR</option>                                   
                                    <option value="3">COMANDA DE TRANSPORT</option>
                                    <option value="4">CONTRACT DE TRANSPORT</option>
                                    <option value="5">OFERTA DE TRANSPORT</option>
                                  </select>
                            </div>
                        </div>
                        <div class="row mt-3 mb-4">
                            <div class="col-sm-4">
                                <label>Limba dorită:</label>
                            </div>
                            <div class="col-sm-8">
                                <select name="selectFormLanguage">
                                    <option></option>
                                    <option value="ro">Română</option>
                                    <option value="us">Engleză</option>
                                  </select>
                            </div>
                        </div>

                        <div class="row text-center">
                            <div class="col">
                                <button type="submit" name="submit" value="Next">MERGI MAI DEPARTE</button>
                            </div>
                        </div>
                    </form>

                    <form [formGroup]="formContractType1" (ngSubmit)="submitForm1()" [hidden]="typeContract!==1">
                        <div class="row mt-4 text-center">
                            <div class="col-sm">
                                <label class="reset-lineh">Numar contract:</label>
                            </div>        
                        </div>
                        <div class="row">
                            <div class="col-lg">
                                <input type="text" placeholder="Nr. contract" class="form-control" formControlName="formContractType1ContractNumber" required>
                            </div>
                            <div class="col-lg-6" [hidden]="1">
                                <input type="text" placeholder="Incheiat la data" class="form-control" formControlName="formContractType1ContractDate" required>
                            </div>
                        </div>   
                        
                        <div class="row mt-4 text-center">
                            <div class="col-sm">
                                <label class="reset-lineh">Date client:</label>
                            </div>        
                        </div>
                        <div class="row">
                            <div class="col-lg">
                                <select name="selectForm1Clients">
                                    <option></option>
                                    <option *ngFor="let client of clients | async" [value]="client.id">{{client.type}}: {{client.name}} | {{client.location}} | {{client.cnp}} {{!!client.cnp ? '' : client.cui + ' | ' + client.cif}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row text-right">
                            <div class="col-lg">
                                <a data-toggle="modal" data-target="#newClientModal" class="new-client"><i class="fas fa-external-link-alt"></i> Adauga un client nou</a>
                            </div>
                        </div>

                        <div class="row mt-4 text-center">
                            <div class="col-sm">
                                <label class="reset-lineh">Mentiuni act aditional:</label>
                            </div>        
                        </div>
                        <div class="row">
                            <div class="col-lg">
                                <textarea rows="15" placeholder="" class="form-control" formControlName="formContractType1Remarks" required=""></textarea>
                            </div>
                        </div>
                        

                        <div class="row mt-3 text-center">
                            <div class="col">
                                <button type="button" name="cancel" value="Cancel" class="mx-3" (click)="cancel()">ANULEAZĂ</button>
                                <button type="submit" name="submit" value="Download">DESCARCĂ DOCUMENTUL</button>
                            </div>
                        </div>
                    </form>

                    <form [formGroup]="formContractType2" (ngSubmit)="submitForm2()" [hidden]="typeContract!==2">
                        <div class="row mt-4 text-center">
                            <div class="col-sm">
                                <label class="reset-lineh">Numar contract:</label>
                            </div>        
                        </div>
                        <div class="row">
                            <div class="col-lg">
                                <input type="text" placeholder="Nr. contract" class="form-control" formControlName="formContractType2ContractNumber" required>
                            </div>
                            <div class="col-lg-6" [hidden]="1">
                                <input type="text" placeholder="Incheiat la data" class="form-control" formControlName="formContractType2ContractDate" required>
                            </div>
                        </div>   

                        <div class="row mt-4 text-center">
                            <div class="col-sm">
                                <label class="reset-lineh">Date client:</label>
                            </div>        
                        </div>
                        <div class="row">
                            <div class="col-lg">
                                <select name="selectForm2Clients">
                                    <option></option>
                                    <option *ngFor="let client of clients | async" [value]="client.id">{{client.type}}: {{client.name}} | {{client.location}} | {{client.cnp}} {{!!client.cnp ? '' : client.cui + ' | ' + client.cif}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row text-right">
                            <div class="col-lg">
                                <a data-toggle="modal" data-target="#newClientModal" class="new-client"><i class="fas fa-external-link-alt"></i> Adauga un client nou</a>
                            </div>
                        </div>
                        
                        <div class="row mt-4 text-center">
                            <div class="col-sm">
                                <label class="reset-lineh">Mentiuni act aditional:</label>
                            </div>        
                        </div>
                        <div class="row">
                            <div class="col-lg">
                                <textarea rows="15" placeholder="" class="form-control" formControlName="formContractType2Remarks" required=""></textarea>
                            </div>
                        </div>
                        

                        <div class="row mt-3 text-center">
                            <div class="col">
                                <button type="button" name="cancel" value="Cancel" class="mx-3" (click)="cancel()">ANULEAZĂ</button>
                                <button type="submit" name="submit" value="Download">DESCARCĂ DOCUMENTUL</button>
                            </div>
                        </div>
                    </form>

                    <form [formGroup]="formContractType3" (ngSubmit)="submitForm3()" [hidden]="typeContract!==3">
                        <div class="row mt-4 text-center">
                            <div class="col-sm">
                                <label class="reset-lineh">Numar contract:</label>
                            </div>        
                        </div>
                        <div class="row">
                            <div class="col-lg">
                                <input type="text" placeholder="Nr. contract" class="form-control" formControlName="formContractType3ContractNumber" required>
                            </div>
                            <div class="col-lg-6" [hidden]="1">
                                <input type="text" placeholder="Din data" class="form-control" formControlName="formContractType3ContractFrom" required>
                            </div>
                        </div>   
                        
                        <div class="row mt-4 text-center">
                            <div class="col-sm">
                                <label class="reset-lineh">Date client:</label>
                            </div>        
                        </div>
                        <div class="row">
                            <div class="col-lg">
                                <select name="selectForm3Clients">
                                    <option></option>
                                    <option *ngFor="let client of clients | async" [value]="client.id">{{client.type}}: {{client.name}} | {{client.location}} | {{client.cnp}} {{!!client.cnp ? '' : client.cui + ' | ' + client.cif}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row text-right">
                            <div class="col-lg">
                                <a data-toggle="modal" data-target="#newClientModal" class="new-client"><i class="fas fa-external-link-alt"></i> Adauga un client nou</a>
                            </div>
                        </div>

                        <div class="row mt-4 text-center">
                            <div class="col-sm">
                                <label class="reset-lineh">Date transport:</label>
                            </div>        
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <input type="text" placeholder="Rută" class="form-control" formControlName="formContractType3TransportRoute" required>
                            </div>
                            <div class="col-lg-6">
                                <input type="text" placeholder="Tip marfă " class="form-control" formControlName="formContractType3TransportType" required>
                            </div>
                        </div> 

                        <div class="row mt-4">
                            <div class="col-lg">
                                <textarea rows="12" placeholder="" class="form-control" formControlName="formContractType3TransportUploadInfo" required=""></textarea>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-lg">
                                <textarea rows="14" placeholder="" class="form-control" formControlName="formContractType3TransportDownloadInfo" required=""></textarea>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-lg-6">
                                <input type="text" placeholder="Valoare marfă" class="form-control" formControlName="formContractType3TransportMerchandiseValue" required>
                            </div>
                            <div class="col-lg-6">
                                <input type="text" placeholder="Tip vehicul " class="form-control" formControlName="formContractType3TransportVehicleType" required>
                            </div>
                        </div>
                        <div class="row text-right">
                            <div class="col-lg">
                                <span class="px-5"><b>REGIM:</b></span>
                                <label>
                                    <input type="radio" value="GR" formControlName="formContractType3TransportVehicleRegime">
                                      <span class="px-1">Grupaj</span>
                                  </label>
                                  <label class="mx-5">
                                    <input type="radio" value="EX" formControlName="formContractType3TransportVehicleRegime">
                                      <span class="px-1">Exclusiv</span>
                                  </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <input type="text" placeholder="Număr auto" class="form-control" formControlName="formContractType3TransportCarNumber" required>
                            </div>
                            <div class="col-lg-6">
                                <input type="text" placeholder="Asigurare CMR" class="form-control" formControlName="formContractType3TransportCRMInsurance" required>
                            </div>
                        </div>  
                        
                        <div class="row mt-4 text-center">
                            <div class="col-sm">
                                <label class="reset-lineh">PREȚ ȘI MODALITĂȚI DE PLATĂ:</label>
                            </div>        
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <input type="text" placeholder="Pret transport" class="form-control" formControlName="formContractType3TransportPrice" required>
                            </div>
                            <div class="col-lg-6">
                                <input type="text" placeholder="Termen de plata (in zile)" class="form-control" formControlName="formContractType3TransportPaymentTerm" required>
                            </div>
                        </div>  

                        <div class="row mt-3 text-center">
                            <div class="col">
                                <button type="button" name="cancel" value="Cancel" class="mx-3" (click)="cancel()">ANULEAZĂ</button>
                                <button type="submit" name="submit" value="Download">DESCARCĂ DOCUMENTUL</button>
                            </div>
                        </div>
                    </form>

                    <form [formGroup]="formContractType4" (ngSubmit)="submitForm4()" [hidden]="typeContract!==4">
                        <div class="row mt-4 text-center">
                            <div class="col-sm">
                                <label class="reset-lineh">Numar contract:</label>
                            </div>        
                        </div>
                        <div class="row">
                            <div class="col-lg">
                                <input type="text" placeholder="Nr. contract" class="form-control" formControlName="formContractType4ContractNumber" required>
                            </div>
                            <div class="col-lg-6" [hidden]="1">
                                <input type="text" placeholder="Din data" class="form-control" formControlName="formContractType4ContractFrom" required>
                            </div>
                        </div>   
                        
                        <div class="row mt-4 text-center">
                            <div class="col-sm">
                                <label class="reset-lineh">Date client:</label>
                            </div>        
                        </div>
                        <div class="row">
                            <div class="col-lg">
                                <select name="selectForm4Clients">
                                    <option></option>
                                    <option *ngFor="let client of clients | async" [value]="client.id">{{client.type}}: {{client.name}} | {{client.location}} | {{client.cnp}} {{!!client.cnp ? '' : client.cui + ' | ' + client.cif}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row text-right">
                            <div class="col-lg">
                                <a data-toggle="modal" data-target="#newClientModal" class="new-client"><i class="fas fa-external-link-alt"></i> Adauga un client nou</a>
                            </div>
                        </div>

                        <div class="row mt-4 text-center">
                            <div class="col-sm">
                                <label class="reset-lineh">Date transport:</label>
                            </div>        
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <input type="text" placeholder="Rută" class="form-control" formControlName="formContractType4TransportRoute" required>
                            </div>
                            <div class="col-lg-6">
                                <input type="text" placeholder="Tip marfă " class="form-control" formControlName="formContractType4TransportType" required>
                            </div>
                        </div> 
                        
                        <div class="row mt-4">
                            <div class="col-lg">
                                <textarea rows="12" placeholder="" class="form-control" formControlName="formContractType4TransportUploadInfo" required=""></textarea>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-lg">
                                <textarea rows="12" placeholder="" class="form-control" formControlName="formContractType4TransportDownloadInfo" required=""></textarea>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-lg-6">
                                <input type="text" placeholder="Valoare marfă" class="form-control" formControlName="formContractType4TransportMerchandiseValue" required>
                            </div>
                            <div class="col-lg-6">
                                <input type="text" placeholder="Tip vehicul " class="form-control" formControlName="formContractType4TransportVehicleType" required>
                            </div>
                        </div>
                        <div class="row text-right">
                            <div class="col-lg">
                                <span class="px-5"><b>REGIM:</b></span>
                                <label>
                                    <input type="radio" value="GR" formControlName="formContractType4TransportVehicleRegime">
                                      <span class="px-1">Grupaj</span>
                                  </label>
                                  <label class="mx-5">
                                    <input type="radio" value="EX" formControlName="formContractType4TransportVehicleRegime">
                                      <span class="px-1">Exclusiv</span>
                                  </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4">
                                <input type="text" placeholder="Număr auto" class="form-control" formControlName="formContractType4TransportCarNumber" required>
                            </div>
                            <div class="col-lg-4">
                                <input type="text" placeholder="Asigurare CMR" class="form-control" formControlName="formContractType4TransportCRMInsurance" required>
                            </div>
                            <div class="col-lg-4">
                                <input type="text" placeholder="Preț transport" class="form-control" formControlName="formContractType4TransportTPrice" required>
                            </div>
                        </div>  

                        <div class="row mt-4 text-center">
                            <div class="col-sm">
                                <label class="reset-lineh">Modalitate de plată:</label>
                            </div>        
                        </div>
                        <div class="row mt-4">
                            <div class="col-lg">
                                <textarea rows="8" placeholder="" class="form-control" formControlName="formContractType4PaymentMethods" required=""></textarea>
                            </div>
                        </div>

                        <div class="row mt-3 text-center">
                            <div class="col">
                                <button type="button" name="cancel" value="Cancel" class="mx-3" (click)="cancel()">ANULEAZĂ</button>
                                <button type="submit" name="submit" value="Download">DESCARCĂ DOCUMENTUL</button>
                            </div>
                        </div>
                    </form>

                    <form [formGroup]="formContractType5" (ngSubmit)="submitForm5()" [hidden]="typeContract!==5">
                        <div class="row mt-4 text-center">
                            <div class="col-sm">
                                <label class="reset-lineh">Numar oferta:</label>
                            </div>        
                        </div>
                        <div class="row text-center">
                            <div class="col-lg">
                                <input type="text" placeholder="Nr. oferta" class="form-control" formControlName="formContractType5OfferNumber" required>
                            </div>
                            <div class="col-lg-6" [hidden]="1">
                                <input type="text" placeholder="Din din data" class="form-control" formControlName="formContractType5OfferFrom" required>
                            </div>
                        </div>   
                        
                        <div class="row mt-4 text-center">
                            <div class="col-sm">
                                <label class="reset-lineh">Date client:</label>
                            </div>        
                        </div>
                        <div class="row">
                            <div class="col-lg">
                                <select name="selectForm5Clients">
                                    <option></option>
                                    <option *ngFor="let client of clients | async" [value]="client.id">{{client.type}}: {{client.name}} | {{client.location}} | {{client.cnp}} {{!!client.cnp ? '' : client.cui + ' | ' + client.cif}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row text-right">
                            <div class="col-lg">
                                <a data-toggle="modal" data-target="#newClientModal" class="new-client"><i class="fas fa-external-link-alt"></i> Adauga un client nou</a>
                            </div>
                        </div>

                        <div class="row mt-4 text-center">
                            <div class="col-sm">
                                <label class="reset-lineh">Date transport:</label>
                            </div>        
                        </div>
                        <div class="row">
                            <div class="col-lg-4">
                                <input type="text" placeholder="Rută" class="form-control" formControlName="formContractType5TransportRoute" required>
                            </div>
                            <div class="col-lg-4">
                                <input type="text" placeholder="Tip marfă " class="form-control" formControlName="formContractType5TransportType" required>
                            </div>
                            <div class="col-lg-4">
                                <input type="text" placeholder="Preț transport" class="form-control" formControlName="formContractType5TransportTPrice" required>
                            </div>
                        </div> 
                        
                        <div class="row mt-4">
                            <div class="col-lg">
                                <textarea rows="12" placeholder="" class="form-control" formControlName="formContractType5TransportUploadInfo" required=""></textarea>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-lg">
                                <textarea rows="12" placeholder="" class="form-control" formControlName="formContractType5TransportDownloadInfo" required=""></textarea>
                            </div>
                        </div>

                        <div class="row mt-4 text-center">
                            <div class="col-sm">
                                <label class="reset-lineh">Modalitate de plată:</label>
                            </div>        
                        </div>
                        <div class="row mt-4">
                            <div class="col-lg">
                                <textarea rows="4" placeholder="" class="form-control" formControlName="formContractType5PaymentMethods" required=""></textarea>
                            </div>
                        </div>

                        <div class="row mt-3 text-center">
                            <div class="col">
                                <button type="button" name="cancel" value="Cancel" class="mx-3" (click)="cancel()">ANULEAZĂ</button>
                                <button type="submit" name="submit" value="Download">DESCARCĂ DOCUMENTUL</button>
                            </div>
                        </div>
                    </form>

                    <div [ngClass]="!submitted? 'd-none' : 'd-block'" class="col-lg-12 mt-3 fade-in">
                        <div class="form-message">{{responseMessage}}</div>
                    </div>

                   <!-- Modal form -->
                    <div class="modal fade" id="newClientModal" tabindex="-1" role="dialog" aria-labelledby="newClientModalLabel" aria-hidden="true" data-backdrop="static">
                        <div class="modal-dialog modal-xl" role="document">
                        <div class="modal-content">
                            <div class="modal-header text-center">
                            <h5 class="modal-title w-100" id="newClientModalLabel">Adauga un client nou</h5>
                            <a type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </a>
                            </div>
                            <form [formGroup]="formNewClient" (ngSubmit)="newClient()">
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-lg">
                                            <label>
                                                <input type="radio" value="PJ" formControlName="formNewClientType">
                                                  <span class="px-1">Persoana juridica</span>
                                              </label>
                                              <label class="mx-5">
                                                <input type="radio" value="PF" formControlName="formNewClientType">
                                                  <span class="px-1">Persoana fizica</span>
                                              </label>
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <input type="text" placeholder="Nume" class="form-control" formControlName="formNewClientName" required>
                                        </div>
                                        <div class="col-lg-6">
                                            <input type="text" placeholder="Sediul / locatie / adresa" class="form-control" formControlName="formNewClientLocation" required>
                                        </div>
                                    </div>     
                                    
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <input type="text" placeholder="Telefon" class="form-control" formControlName="formNewClientPhone" required>
                                        </div>
                                        <div class="col-lg-6">
                                            <input type="text" placeholder="Email" class="form-control" formControlName="formNewClientEmail" required>
                                        </div>
                                    </div>  
                                    <div class="row" *ngIf="formNewClient.get('formNewClientType').value=='PJ'">
                                        <div class="col-lg-4">
                                            <input type="text" placeholder="Cod unic de înregistrare" class="form-control" formControlName="formNewClientCUI">
                                        </div>
                                        <div class="col-lg-4">
                                            <input type="text" placeholder="Nr. Registrul Comerțului" class="form-control" formControlName="formNewClientCIF">
                                        </div>
                                        <div class="col-lg-4">
                                            <input type="text" placeholder="Reprezentată de către" class="form-control" formControlName="formNewClientRepresentative">
                                        </div>
                                    </div>     
                                    
                                    <div class="row" *ngIf="formNewClient.get('formNewClientType').value=='PF'">
                                        <div class="col-lg-6">
                                            <input type="text" placeholder="CNP" class="form-control" formControlName="formNewClientCNP">
                                        </div>
                                    </div>    
                                </div>
                                <div class="modal-footer">
                                <button type="button" data-dismiss="modal">Inchide</button>
                                <button type="submit" name="submit" value="New Client">Salveaza clientul</button>
                                </div>
                            </form>
                        </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>