import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ContractsService } from '../contracts.service';
import { UtilsService } from '../utils.service';
import { ClientsService } from '../clients.service';
import { ScreenManagerService } from '../screen-manager.service';
import { DatePipe } from '@angular/common';
declare var $:any;

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css'],
  providers: [DatePipe]
})
export class InvoicesComponent implements OnInit {

  submitted: boolean = false; // show and hide the success message
  responseMessage: string; // the response message to show to the user
  hintText : string;
  labelText: string;
  errorMessage : string;
  validForm : boolean = true;
  typeContract : number;

  // Contract forms
  formNewClient: FormGroup;
  formContractType1: FormGroup;
  formContractType2: FormGroup;
  formContractType3: FormGroup;
  formContractType4: FormGroup;
  formContractType5: FormGroup;

  dateNow: any = new Date();
  clients;

  isScreenFrozen = false;

  constructor(
      private fb: FormBuilder, private contractsService : ContractsService, private utils: UtilsService,
      private datePipe: DatePipe, private clientsService: ClientsService
    ) {

    this.dateNow = this.datePipe.transform(this.dateNow, 'dd.MM.yyyy');

    this.formNewClient = fb.group({
      'formNewClientType': 'PJ',
      'formNewClientName': '',
      'formNewClientLocation': '',
      'formNewClientPhone': '',
      'formNewClientEmail': '',
      'formNewClientCUI': '',
      'formNewClientCIF': '',
      'formNewClientRepresentative': '',
      'formNewClientCNP': ''
    });

    this.formContractType1 = fb.group({
      'formContractType1ContractNumber': ['', Validators.required],
      'formContractType1ContractDate': [this.dateNow, Validators.required],
      'formContractType1Remarks': ['Având în vedere că am fost informați de client că furnizorul nu are marfa pregătită de încărcare, se modifică data de încărcare astfel:' +
        '\n\nSe modifică numerele auto. Noile numere auto sunt:' +
        '\n\nSe anulează comanda de transport, deoarece marfa nu este pregătită de încărcare. Această anulare nu implică costuri suplimentare.' + 
        '\n\nSe anulează contractul de transport, deoarece marfa nu este pregătită de încărcare. Această anulare nu implică costuri suplimentare.', Validators.required]
    });

    this.formContractType2 = fb.group({
      'formContractType2ContractNumber': ['', Validators.required],
      'formContractType2ContractDate': [this.dateNow, Validators.required],
      'formContractType2Remarks': ['Având în vedere că am fost informați de client că furnizorul nu are marfa pregătită de încărcare, se modifică data de încărcare astfel:' +
        '\n\nSe modifică numerele auto. Noile numere auto sunt:' +
        '\n\nSe anulează comanda de transport, deoarece marfa nu este pregătită de încărcare. Această anulare nu implică costuri suplimentare.' + 
        '\n\nSe anulează contractul de transport, deoarece marfa nu este pregătită de încărcare. Această anulare nu implică costuri suplimentare.', Validators.required]
    });

    this.formContractType3 = fb.group({
      'formContractType3ContractNumber': ['', Validators.required],
      'formContractType3ContractFrom': [this.dateNow, Validators.required],
      'formContractType3TransportRoute': ['', Validators.required],
      'formContractType3TransportType': ['', Validators.required],
      'formContractType3TransportUploadInfo': ['Loc încărcare: ' +
        '\nDată încărcare: ' +
        '\nProgram: ' +
        '\nDetalii marfă: ' +
        '\nSpecificații marfă: ' +
        '\nGreutate totală: ' +
        '\nReferință de încărcare: ' +
        '\nVamă încărcare: ' +
        '\nComisionar vamal: ' +
        '\nObservații: ', Validators.required],
      'formContractType3TransportDownloadInfo': ['Loc descărcare: ' +
        '\nMarfa se descarcă doar la adresa din prezenta comandă ( sau cea din actele adiționale care se vor semna ulterior dacă va fi cazul ). ' +
        '\nDată descărcare: ' +
        '\nProgram: ' +
        '\nDetalii marfă: ' +
        '\nSpecificații marfă: ' +
        '\nGreutate totală: ' +
        '\nReferință: ' +
        '\nVamă descărcare: ' +
        '\nComisionar vamal: ' +
        '\nObservații: ', Validators.required],
      'formContractType3TransportMerchandiseValue': ['', Validators.required],
      'formContractType3TransportVehicleType': ['', Validators.required],
      'formContractType3TransportCarNumber': ['', Validators.required],
      'formContractType3TransportCRMInsurance': ['', Validators.required],
      'formContractType3TransportPrice': ['', Validators.required],
      'formContractType3TransportPaymentTerm': ['', Validators.required],
      'formContractType3TransportVehicleRegime': ['GR', Validators.required]
    });

    this.formContractType4 = fb.group({
      'formContractType4ContractNumber': ['', Validators.required],
      'formContractType4ContractFrom': [this.dateNow, Validators.required],
      'formContractType4TransportRoute': ['', Validators.required],
      'formContractType4TransportType': ['', Validators.required],
      'formContractType4TransportUploadInfo': ['Loc încărcare: ' +
        '\nDată încărcare: ' +
        '\nProgram: ' +
        '\nDetalii marfă: ' +
        '\nSpecificații marfă: ' +
        '\nGreutate totală: ' +
        '\nReferință de încărcare: ' +
        '\nVamă încărcare: ' +
        '\nComisionar vamal: ' +
        '\nObservații: ', Validators.required],
      'formContractType4TransportDownloadInfo': ['Loc descărcare: ' +
        '\nDată descărcare: ' +
        '\nProgram: ' +
        '\nDetalii marfă: ' +
        '\nSpecificații marfă: ' +
        '\nGreutate totală: ' +
        '\nReferință: ' +
        '\nVamă descărcare: ' +
        '\nComisionar vamal: ' +
        '\nObservații: ', Validators.required],
      'formContractType4TransportMerchandiseValue': ['', Validators.required],
      'formContractType4TransportVehicleType': ['', Validators.required],
      'formContractType4TransportCarNumber': ['', Validators.required],
      'formContractType4TransportCRMInsurance': ['', Validators.required],
      'formContractType4TransportTPrice': ['', Validators.required],
      'formContractType4TransportVehicleRegime': ['GR', Validators.required],
      'formContractType4PaymentMethods': [
        'Plata transportului se va face prin transfer bancar/depunere numerar în ziua încărcării. Copie după extrasul de cont cu ștampila băncii, evidențiind tranzacția în cauză, se va transmite către SC C.M.D. IRIS TRUCK SRL cel târziu a doua zi după încărcare.' +
        '\n\nMarfa se va descărca la locația de descărcare numai dacă contravaloarea transportului a fost recepționată în contul bancar al firmei SC C.M.D. IRIS TRUCK SRL.',
        Validators.required
      ]

    });

      this.formContractType5 = fb.group({
      'formContractType5OfferNumber': ['', Validators.required],
      'formContractType5OfferFrom': [this.dateNow, Validators.required],      
      'formContractType5TransportRoute': ['', Validators.required],
      'formContractType5TransportType': ['', Validators.required],
      'formContractType5TransportUploadInfo': ['Loc încărcare: ' +
        '\nDată încărcare: ' +
        '\nProgram: ' +
        '\nDetalii marfă: ' +
        '\nSpecificații marfă: ' +
        '\nGreutate totală: ' +
        '\nReferință de încărcare: ' +
        '\nVamă încărcare: ' +
        '\nComisionar vamal: ' +
        '\nObservații: ', Validators.required],
      'formContractType5TransportDownloadInfo': ['Loc descărcare: ' +
        '\nDată descărcare: ' +
        '\nProgram: ' +
        '\nDetalii marfă: ' +
        '\nSpecificații marfă: ' +
        '\nGreutate totală: ' +
        '\nReferință: ' +
        '\nVamă descărcare: ' +
        '\nComisionar vamal: ' +
        '\nObservații: ', Validators.required],
      'formContractType5TransportTPrice': ['', Validators.required],
      'formContractType5PaymentMethods': [
        'Plata transportului se va face prin transfer bancar/depunere numerar în ziua încărcării. Copie după extrasul de cont cu ștampila băncii, evidențiind tranzacția în cauză, se va transmite către SC C.M.D. IRIS TRUCK SRL cel târziu a doua zi după încărcare.',
         Validators.required
      ]  
    });
  }
  
  mainForm() {
    if ($('select[name="selectFormContract"]').val() !== "" && $('select[name="selectFormLanguage"]').val() !== "") {
      this.errorMessage = "";
      this.validForm = true;
      ScreenManagerService.setScreen(parseInt($('select[name="selectFormContract"]').val()));
      this.typeContract = ScreenManagerService.getScreen();
      this.setLabelAndHint();
    } else {
      this.errorForm();
    }
  }

  cancel() {
    ScreenManagerService.setScreen(0);
    this.typeContract = ScreenManagerService.getScreen();
    location.reload();
  }

  resetScreen() {
    ScreenManagerService.setScreen(0);
    this.typeContract = ScreenManagerService.getScreen();
    this.isScreenFrozen = false;
  }

  newClient() {
    this.clientsService.newClient(this.formNewClient.value).subscribe(() => {
      $('#newClientModal').modal('hide');
      this.clients = this.clientsService.getClients();
    }, error => {
      console.log('Error', error);
    });
  }

  submitForm1() {
    if (this.formContractType1.status == "VALID" && $('select[name="selectForm1Clients"] option:selected').val() !== "") {
      try {
        if ($('select[name="selectFormLanguage"] option:selected').val() === "ro" || $('select[name="selectFormLanguage"] option:selected').val() === "") {
          this.contractsService.contractType1RO(this.formContractType1.value, parseInt($('select[name="selectForm1Clients"] option:selected').val()));
        } else if ($('select[name="selectFormLanguage"] option:selected').val() === "us") {
          this.contractsService.contractType1EN(this.formContractType1.value, parseInt($('select[name="selectForm1Clients"] option:selected').val()));
        }
        this.submitSuccessForm(this.formContractType1);
      } catch (error) {
        this.errorFormServer(error);
      }
    } else {
      this.errorForm();
    }
  }

  submitForm2() {
    if (this.formContractType2.status == "VALID" && $('select[name="selectForm2Clients"] option:selected').val() !== "") {
      try {
        if ($('select[name="selectFormLanguage"] option:selected').val() === "ro" || $('select[name="selectFormLanguage"] option:selected').val() === "") {
          this.contractsService.contractType2RO(this.formContractType2.value, parseInt($('select[name="selectForm2Clients"] option:selected').val()));
        } else if ($('select[name="selectFormLanguage"] option:selected').val() === "us") {
          this.contractsService.contractType2EN(this.formContractType2.value, parseInt($('select[name="selectForm2Clients"] option:selected').val()));
        }
        this.submitSuccessForm(this.formContractType2);
      } catch (error) {
        this.errorFormServer(error);
      }
    } else {
      this.errorForm();
    }
  }

  submitForm3() {
    if (this.formContractType3.status == "VALID" && $('select[name="selectForm3Clients"] option:selected').val() !== "") {
      try {
        if ($('select[name="selectFormLanguage"] option:selected').val() === "ro" || $('select[name="selectFormLanguage"] option:selected').val() === "") {
          this.contractsService.contractType3RO(this.formContractType3.value, parseInt($('select[name="selectForm3Clients"] option:selected').val()));
        } else if ($('select[name="selectFormLanguage"] option:selected').val() === "us") {
          this.contractsService.contractType3EN(this.formContractType3.value, parseInt($('select[name="selectForm3Clients"] option:selected').val()));
        }
        this.submitSuccessForm(this.formContractType3);
      } catch (error) {
        this.errorFormServer(error);
      }
    } else {
      this.errorForm();
    }
  }

  submitForm4() {
    if (this.formContractType4.status == "VALID" && $('select[name="selectForm4Clients"] option:selected').val() !== "") {
      try {
        if ($('select[name="selectFormLanguage"] option:selected').val() === "ro" || $('select[name="selectFormLanguage"] option:selected').val() === "") {
          this.contractsService.contractType4RO(this.formContractType4.value, parseInt($('select[name="selectForm4Clients"] option:selected').val()));
        } else if ($('select[name="selectFormLanguage"] option:selected').val() === "us") {
          this.contractsService.contractType4EN(this.formContractType4.value, parseInt($('select[name="selectForm4Clients"] option:selected').val()));
        }
        this.submitSuccessForm(this.formContractType4);
      } catch (error) {
        this.errorFormServer(error);
      }
    } else {
      this.errorForm();
    }
  }

  submitForm5() {
    if (this.formContractType5.status == "VALID" && $('select[name="selectForm5Clients"] option:selected').val() !== "") {
      try {
        if ($('select[name="selectFormLanguage"] option:selected').val() === "ro" || $('select[name="selectFormLanguage"] option:selected').val() === "") {
          this.contractsService.contractType5RO(this.formContractType5.value, parseInt($('select[name="selectForm5Clients"] option:selected').val()));
        } else if ($('select[name="selectFormLanguage"] option:selected').val() === "us") {
          this.contractsService.contractType5EN(this.formContractType5.value, parseInt($('select[name="selectForm5Clients"] option:selected').val()));
        }
        this.submitSuccessForm(this.formContractType5);
      } catch (error) {
        this.errorFormServer(error);
      }
    } else {
      this.errorForm();
    }
  }

  errorForm() {
    window.scroll(0,0);
    this.errorMessage = "Vă rugăm să completați toate câmpurile de mai jos...";
    this.validForm = false;
  }

  errorFormServer(error: any) {
    this.errorMessage = "Oops! Ceva nu a mers bine... Vă rugăm să încercați din nou.";
    this.validForm = false;
    console.log('Error', error);
  }

  submitSuccessForm(form: any) {
    ScreenManagerService.setScreen(-1);
    this.typeContract = ScreenManagerService.getScreen();
    this.hintText = "";
    this.errorMessage = "";
    this.validForm = true;
    this.submitted = true;
    form.reset();
    window.scrollTo(0, 0);
    setTimeout(function(){
      location.reload();
      ScreenManagerService.setScreen(0);
      this.typeContract = ScreenManagerService.getScreen();
    },15000);
  }

  setLabelAndHint() {
    switch(this.typeContract) {
      case 1: {
        this.labelText = "ACT ADITIONAL - CLIENT";
        if ($('select[name="selectFormLanguage"] option:selected').val() === "us") {
          this.formContractType1.get('formContractType1Remarks')
            .setValue('Given that we have been informed by the customer that the supplier does not have the goods ready for loading, the loading date is changed as follows:' +
          '\n\nChanging vehicle numbers. The new vehicle numbers are:' +
          '\n\nThe transport order is canceled because the goods are not ready for loading. This cancellation does not involve additional costs.' + 
          '\n\nThe contract of carriage is cancelled because the goods are not ready for loading. This cancellation does not involve additional costs.');
        }
        break;
      }
      case 2: {
        this.labelText = "ACT ADITIONAL - TRANSPORTATOR";
        if ($('select[name="selectFormLanguage"] option:selected').val() === "us") {
          this.formContractType2.get('formContractType2Remarks')
            .setValue('Given that we have been informed by the customer that the supplier does not have the goods ready for loading, the loading date is changed as follows:' +
          '\n\nChanging vehicle numbers. The new vehicle numbers are:' +
          '\n\nThe transport order is canceled because the goods are not ready for loading. This cancellation does not involve additional costs.' + 
          '\n\nThe contract of carriage is cancelled because the goods are not ready for loading. This cancellation does not involve additional costs.');
        }
        break;
      }
      case 3: {
        this.labelText = "COMANDA DE TRANSPORT";
        if ($('select[name="selectFormLanguage"] option:selected').val() === "us") {
          this.formContractType3.get('formContractType3TransportUploadInfo')
            .setValue('Place of loading: ' +
            '\nThe goods are unloaded only at the address in this order (or in the additional documents that will be signed later if necessary). ' +
            '\nLoading date: ' +
            '\nTime schedule: ' +
            '\nCargo details: ' +
            '\nCargo Specifications: ' +
            '\nTotal weight: ' +
            '\nReference: ' +
            '\nCustoms loading: ' +
            '\nCustoms commissioner: ' +
            '\nRemarks: ');
          this.formContractType3.get('formContractType3TransportDownloadInfo')
            .setValue('Pace of unloading: ' +
            '\nUnloading date: ' +
            '\nTime schedule: ' +
            '\nCargo details: ' +
            '\nCargo Specifications: ' +
            '\nTotal weight: ' +
            '\nReference: ' +
            '\nCustoms unloading: ' +
            '\nCustoms commissioner: ' +
            '\nRemarks: ');
        }
        break;
      }
      case 4: {
        this.labelText = "CONTRACT DE TRANSPORT";
        if ($('select[name="selectFormLanguage"] option:selected').val() === "us") {
          this.formContractType4.get('formContractType4TransportUploadInfo')
            .setValue('Place of loading: ' +
            '\nLoading date: ' +
            '\nTime schedule: ' +
            '\nCargo details: ' +
            '\nCargo Specifications: ' +
            '\nTotal weight: ' +
            '\nReference: ' +
            '\nCustoms loading: ' +
            '\nCustoms commissioner: ' +
            '\nRemarks: ');
          this.formContractType4.get('formContractType4TransportDownloadInfo')
            .setValue('Pace of unloading: ' +
            '\nUnloading date: ' +
            '\nTime schedule: ' +
            '\nCargo details: ' +
            '\nCargo Specifications: ' +
            '\nTotal weight: ' +
            '\nReference: ' +
            '\nCustoms unloading: ' +
            '\nCustoms commissioner: ' +
            '\nRemarks: ');
          this.formContractType4.get('formContractType4PaymentMethods')
            .setValue('Payment of the shipment will be made by bank transfer / cash deposit on the day of loading. A copy of the bank statement with the bank`s stamp, highlighting the transaction in question, will be sent to SC C.M.D. IRIS TRUCK SRL at the latest the day after loading.' +
            '\n\nThe goods will be unloaded at the unloading location only if the value of the shipment has been received in the bank account of SC C. M. D. IRIS TRUCK SRL.');
        }
        break;
      }
      case 5: {
        this.labelText = "OFERTA DE TRANSPORT";
        if ($('select[name="selectFormLanguage"] option:selected').val() === "us") {
          this.formContractType5.get('formContractType5TransportUploadInfo')
            .setValue('Place of loading: ' +
            '\nLoading date: ' +
            '\nTime schedule: ' +
            '\nCargo details: ' +
            '\nCargo Specifications: ' +
            '\nTotal weight: ' +
            '\nReference: ' +
            '\nCustoms loading: ' +
            '\nCustoms commissioner: ' +
            '\nRemarks: ');
          this.formContractType5.get('formContractType5TransportDownloadInfo')
            .setValue('Pace of unloading: ' +
            '\nUnloading date: ' +
            '\nTime schedule: ' +
            '\nCargo details: ' +
            '\nCargo Specifications: ' +
            '\nTotal weight: ' +
            '\nReference: ' +
            '\nCustoms unloading: ' +
            '\nCustoms commissioner: ' +
            '\nRemarks: ');
          this.formContractType5.get('formContractType5PaymentMethods')
            .setValue('Payment of the shipment will be made by bank transfer / cash deposit on the day of loading. A copy of the bank statement with the bank`s stamp, highlighting the transaction in question, will be sent to SC C.M.D. IRIS TRUCK SRL at the latest the day after loading.');
            
        }
        break;
      }
      default: {
        this.labelText = "CREEAZĂ O FACTURA NOUĂ";
        break;
      }
    }

    if (this.typeContract === 0) {
      this.hintText = "Pentru a începe, vă rugăm să selectați tipul de contract și limba dorită...";
    } else {
      this.hintText = "Pentru a continua, vă rugăm să completați câmpurile de mai jos...";
    }
  }

  ngOnInit(): void {
    this.typeContract = ScreenManagerService.getScreen();
    
    this.setLabelAndHint();
    
    this.responseMessage = "Succes! Factura a fost creata cu succes ^_^"

    this.clients = this.clientsService.getClients();

    if (ScreenManagerService.getScreen() === -1) this.isScreenFrozen = true;

    $(document).ready(function() {

      function formatState (state) {
        if (!state.id) {
          return state.text;
        }
        var $state = $(
          '<span><span class="flag flag-' + state.element.value.toLowerCase() + '"></span><span>' + state.text + '</span></span>'
        );
        return $state;
      };

      $('select[name="selectFormContract"]').select2({
        placeholder: "Alegeți contractul dorit"
      });

      $('select[name="selectFormLanguage"]').select2({
        placeholder: "Alegeți limba dorită",
        templateResult: formatState,
        templateSelection: formatState
      });

      $('select[name="selectFormContract"]').select2({
        placeholder: "Alegeți contractul dorit"
      });

      $('select[name="selectForm1Clients"], select[name="selectForm2Clients"], select[name="selectForm3Clients"], select[name="selectForm4Clients"], select[name="selectForm5Clients"]').select2({
        placeholder: "Alegeți clientul dorit sau adaugati un client nou"
      });

      
    });
  }

}
