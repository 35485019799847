import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {OnInit, Component, ViewChildren, QueryList} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { TablesService, ConcludedContracts, PotentialCustomers, ContractsOrdersProfit, CompanyProfit, CompanyProfitArr, Insurance } from '../tables.service';
import { DatePipe } from '@angular/common';
declare var $:any;

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px'})),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])],
    providers: [DatePipe]
})
export class HistoryComponent implements OnInit {

  temp_id;

  tableView: number;
  columnsKey: string[];
  columnsName: object;

  dataSourceConcludedContracts;
  expandedElementConcludedContracts: ConcludedContracts | null;

  dataSourcePotentialCustomers;
  expandedElementPotentialCustomers: PotentialCustomers | null;

  dataSourceContractsOrdersProfit;
  expandedElementContractsOrdersProfit: ContractsOrdersProfit | null;

  dataSourceCompanyProfit;
  expandedElementCompanyProfit: CompanyProfitArr | null;

  dataSourceInsurance;
  expandedElementInsurance: Insurance | null;  

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  // Forms
  formConcludedContracts: FormGroup;
  formPotentialCustomers: FormGroup;
  formContractsOrdersProfit: FormGroup;
  formCompanyProfit: FormGroup;
  formInsurance: FormGroup;

  // Modals state
  stateModalConcludedContracts: string = "add";
  stateModalPotentialCustomers: string = "add";
  stateModalContractsOrdersProfit: string = "add";
  stateModalCompanyProfit: string = "add";
  stateModalInsurance: string = "add";

  // Date info
  todayDay;
  todayMonth;
  todayYear;

  constructor(private tablesService: TablesService, private fb: FormBuilder, private datePipe: DatePipe) {

    this.todayDay = this.datePipe.transform(new Date(), 'd');
    this.todayMonth = this.datePipe.transform(new Date(), 'M');
    this.todayYear = this.datePipe.transform(new Date(), 'yyyy');

    tablesService.getConcludedContracts().subscribe( data => {
      this.dataSourceConcludedContracts = new MatTableDataSource<ConcludedContracts>(data);
      this.dataSourceConcludedContracts.paginator = this.paginator.toArray()[0];
      this.dataSourceConcludedContracts.paginator._intl.itemsPerPageLabel = "Rezultate pe pagina:";
      this.dataSourceConcludedContracts.sort = this.sort.toArray()[0];
    });

    this.formConcludedContracts = fb.group({
      'formConcludedContractsName': '',
      'formConcludedContractsCUIxCNP': '',
      'formConcludedContractsPhone': '',
      'formConcludedContractsEmail': '',
      'formConcludedContractsDateOfVisit': '',
      'formConcludedContractsNextVisitDate': '',
      'formConcludedContractsContactPerson': '',
      'formConcludedContractsVisitDetails': ''
    });

    tablesService.getPotentialCustomers().subscribe( data => {
      this.dataSourcePotentialCustomers = new MatTableDataSource<PotentialCustomers>(data);
      this.dataSourcePotentialCustomers.paginator = this.paginator.toArray()[1];
      this.dataSourcePotentialCustomers.paginator._intl.itemsPerPageLabel = "Rezultate pe pagina:";
      this.dataSourcePotentialCustomers.sort = this.sort.toArray()[1];
    });

    this.formPotentialCustomers = fb.group({
      'formPotentialCustomersName': '',
      'formPotentialCustomersCUIxCNP': '',
      'formPotentialCustomersPhone': '',
      'formPotentialCustomersEmail': '',
      'formPotentialCustomersDateOfVisit': '',
      'formPotentialCustomersNextVisitDate': '',
      'formPotentialCustomersContactPerson': '',
      'formPotentialCustomersVisitDetails': ''
    });

    tablesService.getContractsOrdersProfit().subscribe( data => {
      this.dataSourceContractsOrdersProfit = new MatTableDataSource<ContractsOrdersProfit>(data);
      this.dataSourceContractsOrdersProfit.paginator = this.paginator.toArray()[2];
      this.dataSourceContractsOrdersProfit.paginator._intl.itemsPerPageLabel = "Rezultate pe pagina:";
      this.dataSourceContractsOrdersProfit.sort = this.sort.toArray()[2];
    });

    this.formContractsOrdersProfit = fb.group({
      'formContractsOrdersProfitName': '',
      'formContractsOrdersProfitTransporter': '',
      'formContractsOrdersProfitPrice': '',
      'formContractsOrdersProfitTransporterPrice': '',
      'formContractsOrdersProfitProfit': '',
      'formContractsOrdersProfitUploadDate': '',
      'formContractsOrdersProfitDownloadDate': '',
      'formContractsOrdersProfitUploadStatus': '',
      'formContractsOrdersProfitDownloadStatus': '',
      'formContractsOrdersProfitInvoiceNo': '',
      'formContractsOrdersProfitInvoiceNoTransporter': '',
      'formContractsOrdersProfitPaymentTransporter': '',
      'formContractsOrdersProfitPaymentClient': '',
      'formContractsOrdersProfitDocStatusTransporter': '',
      'formContractsOrdersProfitDateSendDocToClient': ''
    });                

    tablesService.getCompanyProfit().subscribe( data => {
      this.dataSourceCompanyProfit = new MatTableDataSource<CompanyProfitArr>(data);
      this.dataSourceCompanyProfit.paginator = this.paginator.toArray()[3];
      this.dataSourceCompanyProfit.paginator._intl.itemsPerPageLabel = "Rezultate pe pagina:";
      this.dataSourceCompanyProfit.sort = this.sort.toArray()[3];
    });

    this.formCompanyProfit = fb.group({
      'formCompanyProfitDay': '',
      'formCompanyProfitMonth': '',
      'formCompanyProfitYear': '',
      'formCompanyProfitRON': '',
      'formCompanyProfitEURO': '',
    });

    tablesService.getInsurance().subscribe( data => {
      this.dataSourceInsurance = new MatTableDataSource<Insurance>(data);
      this.dataSourceInsurance.paginator = this.paginator.toArray()[4];
      this.dataSourceInsurance.paginator._intl.itemsPerPageLabel = "Rezultate pe pagina:";
      this.dataSourceInsurance.sort = this.sort.toArray()[4];
    });

    this.formInsurance = fb.group({
      'formInsuranceTransporter': '',
      'formInsuranceCarNumber': '',
      'formInsuranceSemitrailer': '',
      'formInsuranceStartDate': '',
      'formInsuranceLastDate': '',
      'formInsuranceEndDate': '',
      'formInsuranceInsuranceValue': '',
    });

  }

  ngOnInit() {
    this.loadConcludedContracts();
  }

  filterConcludedContracts(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceConcludedContracts.filter = filterValue.trim().toLowerCase();
  }

  filterPotentialCustomers(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourcePotentialCustomers.filter = filterValue.trim().toLowerCase();
  }

  filterContractsOrdersProfit(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceContractsOrdersProfit.filter = filterValue.trim().toLowerCase();
  }

  filterCompanyProfit(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceCompanyProfit.filter = filterValue.trim().toLowerCase();
  }

  filterInsurance(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceInsurance.filter = filterValue.trim().toLowerCase();
  }

  loadConcludedContracts() {
    this.tableView = 1;
    this.columnsKey = ['id', 'name', 'CUI_CNP', 'contactPerson', 'dateOfVisit', 'actions'];
    this.columnsName = {id: '#', name: 'Nume', CUI_CNP: 'CUI / CNP', contactPerson: 'Persoană de contact', dateOfVisit: 'Dată vizită', actions: 'Operații'};
  }

  loadPotentialCustomers() {
    this.tableView = 2;
    this.columnsKey = ['id', 'name', 'CUI_CNP', 'contactPerson', 'dateOfVisit', 'actions'];
    this.columnsName = {id: '#', name: 'Nume', CUI_CNP: 'CUI / CNP', contactPerson: 'Persoană de contact', dateOfVisit: 'Dată vizită', actions: 'Operații'};
  }

  loadContractsOrdersProfit() {
    this.tableView = 3;
    this.columnsKey = ['id', 'name', 'transporter', 'profit', 'uploadDate', 'actions'];
    this.columnsName = {id: '#', name: 'Nume client', transporter: 'Nume transportator', profit: 'Profit', uploadDate: 'Dată incarcare', actions: 'Operații'};
  }

  loadCompanyProfit() {
    this.tableView = 4;
    this.columnsKey = ['id', 'month', 'year', 'TotalRON', 'TotalEURO', 'actions'];
    this.columnsName = {id: '#', month: 'Luna', year: 'An', TotalRON: 'Total RON', TotalEURO: 'Total EURO', actions: 'Operații'};
  }

  loadInsurance() {
    this.tableView = 5;
    this.columnsKey = ['id', 'transporter', 'carNumber', 'startDate', 'insuranceValue', 'actions'];
    this.columnsName = {id: '#', transporter: 'Nume transportator', carNumber: 'Numar masina', startDate: 'Dată inceput', insuranceValue: 'Valoare asigurare', actions: 'Operații'};
  }

  // Concluded contracs

  openConcludedContractsModal() {
    this.stateModalConcludedContracts = "add";
    this.formConcludedContracts.reset();
    this.formConcludedContracts.get('formConcludedContractsVisitDetails').setValue('Transportul s-a efectuat conform contractului de transport');
    $('#concludedContractsModal').modal('show');
  }

  addOrEditConcludedContracts() {
    if (this.stateModalConcludedContracts === "add") this.addConcludedContracts();
    else this.editAndSaveConcludedContracts();
  }

  addConcludedContracts() {
    this.tablesService.newConcludedContracts(this.formConcludedContracts.value).subscribe(() => {
      $('#concludedContractsModal').modal('hide');
      this.tablesService.getConcludedContracts().subscribe( data => {
        this.dataSourceConcludedContracts = new MatTableDataSource<ConcludedContracts>(data);
        this.dataSourceConcludedContracts.paginator = this.paginator.toArray()[0];
        this.dataSourceConcludedContracts.sort = this.sort.toArray()[0];
      });
    }, error => {
      console.log('Error', error);
    });
  }

  editConcludedContracts(id, name, CUIxCNP, phone, email, dateOfVisit, nextVisitDate, contactPerson, visitDetails) {
    this.openConcludedContractsModal();

    this.stateModalConcludedContracts = "edit";

    this.formConcludedContracts.get('formConcludedContractsName').setValue(name);
    this.formConcludedContracts.get('formConcludedContractsCUIxCNP').setValue(CUIxCNP);
    this.formConcludedContracts.get('formConcludedContractsPhone').setValue(phone)
    this.formConcludedContracts.get('formConcludedContractsEmail').setValue(email);
    this.formConcludedContracts.get('formConcludedContractsDateOfVisit').setValue(dateOfVisit);
    this.formConcludedContracts.get('formConcludedContractsNextVisitDate').setValue(nextVisitDate);
    this.formConcludedContracts.get('formConcludedContractsContactPerson').setValue(contactPerson);
    this.formConcludedContracts.get('formConcludedContractsVisitDetails').setValue(visitDetails);

    this.temp_id = id;
  }

  editAndSaveConcludedContracts() {
    this.tablesService.editConcludedContracts(this.temp_id, this.formConcludedContracts.value).subscribe(() => {
      $('#concludedContractsModal').modal('hide');
      this.tablesService.getConcludedContracts().subscribe( data => {
        this.dataSourceConcludedContracts = new MatTableDataSource<ConcludedContracts>(data);
        this.dataSourceConcludedContracts.paginator = this.paginator.toArray()[0];
        this.dataSourceConcludedContracts.sort = this.sort.toArray()[0];
      });
    }, error => {
      console.log('Error', error);
    });
  }

  deleteConcludedContracts(id) {
    this.tablesService.deleteConcludedContracts(id).subscribe(() => {
      this.tablesService.getConcludedContracts().subscribe( data => {
        this.dataSourceConcludedContracts = new MatTableDataSource<ConcludedContracts>(data);
        this.dataSourceConcludedContracts.paginator = this.paginator.toArray()[0];
        this.dataSourceConcludedContracts.sort = this.sort.toArray()[0];
      });
    }, error => {
      console.log('Error', error);
    });
  }

  // Potential customers

  openPotentialCustomersModal() {
    this.stateModalPotentialCustomers = "add";
    this.formPotentialCustomers.reset();
    this.formPotentialCustomers.get('formPotentialCustomersVisitDetails').setValue('Clientul are nevoie de transport. Ma va contacta. Are datele mele de contact.');
    $('#potentialCustomersModal').modal('show');
  }

  addOrEditPotentialCustomers() {
    if (this.stateModalPotentialCustomers === "add") this.addPotentialCustomers();
    else this.editAndSavePotentialCustomers();
  }

  addPotentialCustomers() {
    this.tablesService.newPotentialCustomers(this.formPotentialCustomers.value).subscribe(() => {
      $('#potentialCustomersModal').modal('hide');
      this.tablesService.getPotentialCustomers().subscribe( data => {
        this.dataSourcePotentialCustomers = new MatTableDataSource<PotentialCustomers>(data);
        this.dataSourcePotentialCustomers.paginator = this.paginator.toArray()[1];
        this.dataSourcePotentialCustomers.sort = this.sort.toArray()[1];
      });
    }, error => {
      console.log('Error', error);
    });
  }

  editPotentialCustomers(id, name, CUIxCNP, phone, email, dateOfVisit, nextVisitDate, contactPerson, visitDetails) {
    this.openPotentialCustomersModal();

    this.stateModalPotentialCustomers = "edit";

    this.formPotentialCustomers.get('formPotentialCustomersName').setValue(name);
    this.formPotentialCustomers.get('formPotentialCustomersCUIxCNP').setValue(CUIxCNP);
    this.formPotentialCustomers.get('formPotentialCustomersPhone').setValue(phone)
    this.formPotentialCustomers.get('formPotentialCustomersEmail').setValue(email);
    this.formPotentialCustomers.get('formPotentialCustomersDateOfVisit').setValue(dateOfVisit);
    this.formPotentialCustomers.get('formPotentialCustomersNextVisitDate').setValue(nextVisitDate);
    this.formPotentialCustomers.get('formPotentialCustomersContactPerson').setValue(contactPerson);
    this.formPotentialCustomers.get('formPotentialCustomersVisitDetails').setValue(visitDetails);

    this.temp_id = id;
  }

  editAndSavePotentialCustomers() {
    this.tablesService.editPotentialCustomers(this.temp_id, this.formPotentialCustomers.value).subscribe(() => {
      $('#potentialCustomersModal').modal('hide');
      this.tablesService.getPotentialCustomers().subscribe( data => {
        this.dataSourcePotentialCustomers = new MatTableDataSource<PotentialCustomers>(data);
        this.dataSourcePotentialCustomers.paginator = this.paginator.toArray()[1];
        this.dataSourcePotentialCustomers.sort = this.sort.toArray()[1];
      });
    }, error => {
      console.log('Error', error);
    });
  }

  deletePotentialCustomers(id) {
    this.tablesService.deletePotentialCustomers(id).subscribe(() => {
      this.tablesService.getPotentialCustomers().subscribe( data => {
        this.dataSourcePotentialCustomers = new MatTableDataSource<PotentialCustomers>(data);
        this.dataSourcePotentialCustomers.paginator = this.paginator.toArray()[1];
        this.dataSourcePotentialCustomers.sort = this.sort.toArray()[1];
      });
    }, error => {
      console.log('Error', error);
    });
  }

  // Contracts Orders Profit

  openContractsOrdersProfitModal() {
    this.stateModalContractsOrdersProfit = "add";
    this.formContractsOrdersProfit.reset();
    $('#contractsOrdersProfitModal').modal('show');
  }

  addOrEditContractsOrdersProfit() {
    if (this.stateModalContractsOrdersProfit === "add") this.addContractsOrdersProfit();
    else this.editAndSaveContractsOrdersProfit();
  }

  addContractsOrdersProfit() {
    this.tablesService.newContractsOrdersProfit(this.formContractsOrdersProfit.value).subscribe(() => {
      $('#contractsOrdersProfitModal').modal('hide');
      this.tablesService.getContractsOrdersProfit().subscribe( data => {
        this.dataSourceContractsOrdersProfit = new MatTableDataSource<ContractsOrdersProfit>(data);
        this.dataSourceContractsOrdersProfit.paginator = this.paginator.toArray()[2];
        this.dataSourceContractsOrdersProfit.sort = this.sort.toArray()[2];
      });
    }, error => {
      console.log('Error', error);
    });
  }

  editContractsOrdersProfit(id, name, transporter, price, transporterPrice, profit, uploadDate, downloadDate, uploadStatus, downloadStatus,
    invoiceNo, invoiceNoTransporter, paymentTransporter, paymentClient, docStatusTransporter, dateSendDocToClient) {
    this.openContractsOrdersProfitModal();

    this.stateModalContractsOrdersProfit = "edit";

    this.formContractsOrdersProfit.get('formContractsOrdersProfitName').setValue(name);
    this.formContractsOrdersProfit.get('formContractsOrdersProfitTransporter').setValue(transporter);
    this.formContractsOrdersProfit.get('formContractsOrdersProfitPrice').setValue(price);
    this.formContractsOrdersProfit.get('formContractsOrdersProfitTransporterPrice').setValue(transporterPrice);
    this.formContractsOrdersProfit.get('formContractsOrdersProfitProfit').setValue(profit);
    this.formContractsOrdersProfit.get('formContractsOrdersProfitUploadDate').setValue(uploadDate);
    this.formContractsOrdersProfit.get('formContractsOrdersProfitDownloadDate').setValue(downloadDate);
    this.formContractsOrdersProfit.get('formContractsOrdersProfitUploadStatus').setValue(uploadStatus);
    this.formContractsOrdersProfit.get('formContractsOrdersProfitDownloadStatus').setValue(downloadStatus);
    this.formContractsOrdersProfit.get('formContractsOrdersProfitInvoiceNo').setValue(invoiceNo);
    this.formContractsOrdersProfit.get('formContractsOrdersProfitInvoiceNoTransporter').setValue(invoiceNoTransporter);
    this.formContractsOrdersProfit.get('formContractsOrdersProfitPaymentTransporter').setValue(paymentTransporter);
    this.formContractsOrdersProfit.get('formContractsOrdersProfitPaymentClient').setValue(paymentClient);
    this.formContractsOrdersProfit.get('formContractsOrdersProfitDocStatusTransporter').setValue(docStatusTransporter);
    this.formContractsOrdersProfit.get('formContractsOrdersProfitDateSendDocToClient').setValue(dateSendDocToClient);

    this.temp_id = id;
  }

  editAndSaveContractsOrdersProfit() {
    this.tablesService.editContractsOrdersProfit(this.temp_id, this.formContractsOrdersProfit.value).subscribe(() => {
      $('#contractsOrdersProfitModal').modal('hide');
      this.tablesService.getContractsOrdersProfit().subscribe( data => {
        this.dataSourceContractsOrdersProfit = new MatTableDataSource<ContractsOrdersProfit>(data);
        this.dataSourceContractsOrdersProfit.paginator = this.paginator.toArray()[2];
        this.dataSourceContractsOrdersProfit.sort = this.sort.toArray()[2];
      });
    }, error => {
      console.log('Error', error);
    });
  }

  deleteContractsOrdersProfit(id) {
    this.tablesService.deleteContractsOrdersProfit(id).subscribe(() => {
      this.tablesService.getContractsOrdersProfit().subscribe( data => {
        this.dataSourceContractsOrdersProfit = new MatTableDataSource<ContractsOrdersProfit>(data);
        this.dataSourceContractsOrdersProfit.paginator = this.paginator.toArray()[2];
        this.dataSourceContractsOrdersProfit.sort = this.sort.toArray()[2];
      });
    }, error => {
      console.log('Error', error);
    });
  }

  // Company profit

  openCompanyProfitModal() {
    this.stateModalCompanyProfit = "add";
    this.formCompanyProfit.reset();
    this.formCompanyProfit.get('formCompanyProfitDay').setValue(this.todayDay);
    this.formCompanyProfit.get('formCompanyProfitMonth').setValue(this.todayMonth);
    this.formCompanyProfit.get('formCompanyProfitYear').setValue(this.todayYear);
    $('#companyProfitModal').modal('show');
  }

  addOrEditCompanyProfit() {
    if (this.stateModalCompanyProfit === "add") this.addCompanyProfit();
    else this.editAndSaveCompanyProfit();
  }

  addCompanyProfit() {
    this.tablesService.newCompanyProfit(this.formCompanyProfit.value).subscribe(() => {
      $('#companyProfitModal').modal('hide');
      this.tablesService.getCompanyProfit().subscribe( data => {
        this.dataSourceCompanyProfit = new MatTableDataSource<CompanyProfitArr>(data);
        this.dataSourceCompanyProfit.paginator = this.paginator.toArray()[3];
        this.dataSourceCompanyProfit.sort = this.sort.toArray()[3];
      });
    }, error => {
      console.log('Error', error);
    });
  }

  editCompanyProfit(month, year) {
    this.openCompanyProfitModal();

    this.stateModalCompanyProfit = "edit";

    this.formCompanyProfit.get('formCompanyProfitDay').setValue('');
    this.formCompanyProfit.get('formCompanyProfitMonth').setValue(month);
    this.formCompanyProfit.get('formCompanyProfitYear').setValue(year)
  }

  editAndSaveCompanyProfit() {
    this.tablesService.editCompanyProfit(this.formCompanyProfit.value).subscribe(() => {
      $('#companyProfitModal').modal('hide');
      this.tablesService.getCompanyProfit().subscribe( data => {
        this.dataSourceCompanyProfit = new MatTableDataSource<CompanyProfitArr>(data);
        this.dataSourceCompanyProfit.paginator = this.paginator.toArray()[3];
        this.dataSourceCompanyProfit.sort = this.sort.toArray()[3];
      });
    }, error => {
      console.log('Error', error);
    });
  }

  deleteCompanyProfit(id) {
    this.tablesService.deleteCompanyProfit(id).subscribe(() => {
      this.tablesService.getCompanyProfit().subscribe( data => {
        this.dataSourceCompanyProfit = new MatTableDataSource<CompanyProfitArr>(data);
        this.dataSourceCompanyProfit.paginator = this.paginator.toArray()[3];
        this.dataSourceCompanyProfit.sort = this.sort.toArray()[3];
      });
    }, error => {
      console.log('Error', error);
    });
  }

  // Insurance

  openInsuranceModal() {
    this.stateModalInsurance = "add";
    this.formInsurance.reset();
    $('#insuranceModal').modal('show');
  }

  addOrEditInsurance() {
    if (this.stateModalInsurance === "add") this.addInsurance();
    else this.editAndSaveInsurance();
  }

  addInsurance() {
    this.tablesService.newInsurance(this.formInsurance.value).subscribe(() => {
      $('#insuranceModal').modal('hide');
      this.tablesService.getInsurance().subscribe( data => {
        this.dataSourceInsurance = new MatTableDataSource<Insurance>(data);
        this.dataSourceInsurance.paginator = this.paginator.toArray()[4];
        this.dataSourceInsurance.sort = this.sort.toArray()[4];
      });
    }, error => {
      console.log('Error', error);
    });
  }

  editInsurance(id, transporter, carNumber, semitrailer, startDate, lastDate, endDate, insuranceValue) {
    this.openInsuranceModal();

    this.stateModalInsurance = "edit";

    this.formInsurance.get('formInsuranceTransporter').setValue(transporter);
    this.formInsurance.get('formInsuranceCarNumber').setValue(carNumber);
    this.formInsurance.get('formInsuranceSemitrailer').setValue(semitrailer)
    this.formInsurance.get('formInsuranceStartDate').setValue(startDate);
    this.formInsurance.get('formInsuranceLastDate').setValue(lastDate);
    this.formInsurance.get('formInsuranceEndDate').setValue(endDate);
    this.formInsurance.get('formInsuranceInsuranceValue').setValue(insuranceValue);

    this.temp_id = id;
  }

  editAndSaveInsurance() {
    this.tablesService.editInsurance(this.temp_id, this.formInsurance.value).subscribe(() => {
      $('#insuranceModal').modal('hide');
      this.tablesService.getInsurance().subscribe( data => {
        this.dataSourceInsurance = new MatTableDataSource<Insurance>(data);
        this.dataSourceInsurance.paginator = this.paginator.toArray()[4];
        this.dataSourceInsurance.sort = this.sort.toArray()[4];
      });
    }, error => {
      console.log('Error', error);
    });
  }

  deleteInsurance(id) {
    this.tablesService.deleteInsurance(id).subscribe(() => {
      this.tablesService.getInsurance().subscribe( data => {
        this.dataSourceInsurance = new MatTableDataSource<Insurance>(data);
        this.dataSourceInsurance.paginator = this.paginator.toArray()[4];
        this.dataSourceInsurance.sort = this.sort.toArray()[4];
      });
    }, error => {
      console.log('Error', error);
    });
  }

}
