import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { Client, ClientsService } from './clients.service';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { AuthenticationService } from './_services';
import { User } from './_models';

@Injectable({
  providedIn: 'root'
})
export class ContractsService {

  currentUser: User;

  constructor(private utils: UtilsService, private clientsService: ClientsService, private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
   }

  contractType1RO(formContent: any, id: number) {
    this.clientsService.getClient(id).subscribe( client => {
      let docDefinition = {
        content: [
          {
            text: 'ACT ADIȚIONAL NR 1',
            style: 'title'
          },
          {
            text: '\nLA CONTRACTUL NR. ' + this.utils.getOrElse(formContent['formContractType1ContractNumber']),
            style: 'paragraph',
            alignment: 'center'
          },
          {
            text: '\n\n\n\nPĂRȚILE CONTRACTANTE:',
            style: 'paragraph',
            bold: true,
            underline: true
          },
          {
            text: [
              {text: '\nSC C.M.D. IRIS TRUCK SRL', bold: true},
              ', cu sediul social în Municipiul Sibiu, Strada VASILE AARON, Nr. 22, Bloc 80, Scara E, Etaj 5, Ap. M4 (72), Județ Sibiu, Înregistrată la Registrul Comerțului sub număr J32/389/01.03.2021, având CUI RO 43821263, având cont bancar deschis la Banca ING Sibiu, reprezentată legal prin ' + `${this.currentUser.firstName} ${this.currentUser.lastName}`.toUpperCase() + ' - coordonator transport, telefon ' + this.currentUser.phone + ', e-mail ' + this.currentUser.email + ', în calitate de PRESTATOR.'],
            style: 'paragraph'
          },
          {
            text: [
              '\nȘI\n\n',
              this.utils.clientFullTextRO(client),
              {text: 'CLIENT.', bold: true}
            ],
            style: 'paragraph'
          },
          {
            text: '\n\nOBIECTUL ACTULUI ADIȚIONAL:',
            style: 'paragraph',
            bold: true,
            underline: true
          },
          {
            text: '\nPrin prezentul act adițional se menționează următoarele:\n\n',
            style: 'paragraph'
          },
          {
            ol: formContent['formContractType1Remarks'].split('\n\n'),
            style: 'paragraph',
            margin: [20, 0, 0, 0]
          },
          {
            text: [
              '\nRestul clauzelor rămân neschimbate',
              '\n\nÎncheiat astăzi, ' + this.utils.getOrElse(formContent['formContractType1ContractDate']) + '\n\n'
            ],
            style: 'paragraph'
          },
          {
            columns: [
              [{text: 'PRESTATOR'}],
              [{text: 'CLIENT', aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,20,0,0]
          },
          {
            columns: [
              [{text: 'SC C.M.D IRIS TRUCK SRL'}],
              [{text: this.utils.clientFormatName(client), aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: `${this.currentUser.firstName} ${this.currentUser.lastName}`.toUpperCase()}],
              [{text: client.representative, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'Coordonator Transport'}],
              [{text: client.type==='PJ'? 'Reprezentant' : '', aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'Mobil: ' + this.currentUser.phone}],
              [{text: 'Mobil: ' + client.phone, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'E-mail: ' + this.currentUser.email}],
              [{text: 'E-mail: ' + client.email, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            image: this.utils.signature(this.currentUser.id),
            width: 150
          }    
        ],
        styles: {
          title: {
            bold: true,
            fontSize: 12,
            alignment: 'center'
          },
          paragraph: {
            fontSize: 11
          }
        }
      };
    
      return pdfMake.createPdf(docDefinition).download("act_aditional_client_nr_" + this.utils.getOrElse(formContent['formContractType1ContractNumber']));
    });
  }

  contractType1EN(formContent: any, id: number) {
    this.clientsService.getClient(id).subscribe( client => {
      let docDefinition = {
        content: [
          {
            text: 'ADDENDUM NO 1',
            style: 'title'
          },
          {
            text: '\nOF THE CONTRACT NO. ' + this.utils.getOrElse(formContent['formContractType1ContractNumber']),
            style: 'paragraph',
            alignment: 'center'
          },
          {
            text: '\n\n\n\nCONTRACTING PARTIES:',
            style: 'paragraph',
            bold: true,
            underline: true
          },
          {
            text: [
              {text: '\nSC C.M.D. IRIS TRUCK SRL', bold: true},
              ', with Registered office in Sibiu, Vasile ARON Street, No. 22, Block 80, Entrance E, Floor 5, Ap. M4 (72), Sibiu County, registered at the Trade Register under number J32/389/01.03.2021, with CUI RO 43821263, with bank account opened at Banca ing Sibiu, legally represented by ' + `${this.currentUser.firstName} ${this.currentUser.lastName}`.toUpperCase() + ' - transport coordinator, phone ' + this.currentUser.phone + ', e-mail ' + this.currentUser.email + ', as a provider.'],
            style: 'paragraph'
          },
          {
            text: [
              '\nAnd\n\n',
              this.utils.clientFullTextEN(client),
              {text: 'CUSTOMER.', bold: true}
            ],
            style: 'paragraph'
          },
          {
            text: '\n\nOBJECT OF THE ADDENDUM:',
            style: 'paragraph',
            bold: true,
            underline: true
          },
          {
            text: '\nThe following shall be mentioned in this addendum:\n\n',
            style: 'paragraph'
          },
          {
            ol: formContent['formContractType1Remarks'].split('\n\n'),
            style: 'paragraph',
            margin: [20, 0, 0, 0]
          },
          {
            text: [
              '\nThe remaining clauses remain unchanged',
              '\n\nConcluded today, on the ' + this.utils.getOrElse(formContent['formContractType1ContractDate']) + '\n\n'
            ],
            style: 'paragraph'
          },
          {
            columns: [
              [{text: 'PROVIDER'}],
              [{text: 'CUSTOMER', aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,20,0,0]
          },
          {
            columns: [
              [{text: 'SC C.M.D IRIS TRUCK SRL'}],
              [{text: this.utils.clientFormatName(client), aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: `${this.currentUser.firstName} ${this.currentUser.lastName}`.toUpperCase()}],
              [{text: client.representative, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'Transport Coordinator'}],
              [{text: client.type==='PJ'? 'Representative' : '', aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'Mobile: ' + this.currentUser.phone}],
              [{text: 'Mobile: ' + client.phone, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'E-mail: ' + this.currentUser.email}],
              [{text: 'E-mail: ' + client.email, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            image: this.utils.signature(this.currentUser.id),
            width: 150
          }    
        ],
        styles: {
          title: {
            bold: true,
            fontSize: 12,
            alignment: 'center'
          },
          paragraph: {
            fontSize: 11
          }
        }
      };
    
      return pdfMake.createPdf(docDefinition).download("act_aditional_client_nr_" + this.utils.getOrElse(formContent['formContractType1ContractNumber']));
    });
  }

  contractType2RO(formContent: any, id: number) {
    this.clientsService.getClient(id).subscribe( client => {
      let docDefinition = {
        content: [
          {
            text: 'ACT ADIȚIONAL NR 1',
            style: 'title'
          },
          {
            text: '\nLA CONTRACTUL NR. ' + this.utils.getOrElse(formContent['formContractType2ContractNumber']),
            style: 'paragraph',
            alignment: 'center'
          },
          {
            text: '\n\n\n\nPĂRȚILE CONTRACTANTE:',
            style: 'paragraph',
            bold: true,
            underline: true
          },
          {
            text: [
              {text: '\nSC C.M.D. IRIS TRUCK SRL', bold: true},
              ', cu sediul social în Municipiul Sibiu, Strada VASILE AARON, Nr. 22, Bloc 80, Scara E, Etaj 5, Ap. M4 (72), Județ Sibiu, Înregistrată la Registrul Comerțului sub număr J32/389/01.03.2021, având CUI RO 43821263, având cont bancar deschis la Banca ING Sibiu, reprezentată legal prin ' + `${this.currentUser.firstName} ${this.currentUser.lastName}`.toUpperCase() + ' - coordonator transport, telefon ' + this.currentUser.phone + ', e-mail ' + this.currentUser.email + ', în calitate de CASĂ DE EXPEDIȚII.'],
            style: 'paragraph'
          },
          {
            text: [
              '\nȘI\n\n',
              this.utils.clientFullTextRO(client),
              {text: 'TRANSPORTATOR.', bold: true}
            ],
            style: 'paragraph'
          },
          {
            text: '\n\nOBIECTUL ACTULUI ADIȚIONAL:',
            style: 'paragraph',
            bold: true,
            underline: true
          },
          {
            text: '\nPrin prezentul act adițional se menționează următoarele:\n\n',
            style: 'paragraph'
          },
          {
            ol: formContent['formContractType2Remarks'].split('\n\n'),
            style: 'paragraph',
            margin: [20, 0, 0, 0]
          },
          {
            text: [
              '\nRestul clauzelor rămân neschimbate',
              '\n\nÎncheiat astăzi, ' + this.utils.getOrElse(formContent['formContractType2ContractDate']) + '\n\n'
            ],
            style: 'paragraph'
          },
          {
            columns: [
              [{text: 'CASĂ DE EXPEDIȚII'}],
              [{text: 'TRANSPORTATOR', aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,20,0,0]
          },
          {
            columns: [
              [{text: 'SC C.M.D IRIS TRUCK SRL'}],
              [{text: this.utils.clientFormatName(client), aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: `${this.currentUser.firstName} ${this.currentUser.lastName}`.toUpperCase()}],
              [{text: client.representative, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'Coordonator Transport'}],
              [{text: client.type==='PJ'? 'Reprezentant' : '', aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'Mobil: ' + this.currentUser.phone}],
              [{text: 'Mobil: ' + client.phone, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'E-mail: ' + this.currentUser.email}],
              [{text: 'E-mail: ' + client.email, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            image: this.utils.signature(this.currentUser.id),
            width: 150
          }    
        ],
        styles: {
          title: {
            bold: true,
            fontSize: 12,
            alignment: 'center'
          },
          paragraph: {
            fontSize: 11
          }
        }
      };


      return pdfMake.createPdf(docDefinition).download("act_aditional_transportator_nr_" + this.utils.getOrElse(formContent['formContractType2ContractNumber']));
    });
  }

  contractType2EN(formContent: any, id: number) {
    this.clientsService.getClient(id).subscribe( client => {
      let docDefinition = {
        content: [
          {
            text: 'ADDENDUM NO 1',
            style: 'title'
          },
          {
            text: '\nOF THE CONTRACT NO. ' + this.utils.getOrElse(formContent['formContractType2ContractNumber']),
            style: 'paragraph',
            alignment: 'center'
          },
          {
            text: '\n\n\n\nCONTRACTING PARTIES:',
            style: 'paragraph',
            bold: true,
            underline: true
          },
          {
            text: [
              {text: '\nSC C.M.D. IRIS TRUCK SRL', bold: true},
              ', with Registered office in Sibiu, Vasile ARON Street, No. 22, Block 80, Entrance E, Floor 5, Ap. M4 (72), Sibiu County, registered at the Trade Register under number J32/389/01.03.2021, with CUI RO 43821263, with bank account opened at Banca ing Sibiu, legally represented by ' + `${this.currentUser.firstName} ${this.currentUser.lastName}`.toUpperCase() + ' - transport coordinator, phone ' + this.currentUser.phone + ', e-mail ' + this.currentUser.email + ', as a Shipping House.'],
            style: 'paragraph'
          },
          {
            text: [
              '\nAnd\n\n',
              this.utils.clientFullTextEN(client),
              {text: 'CARRIER.', bold: true}
            ],
            style: 'paragraph'
          },
          {
            text: '\n\nOBJECT OF THE ADDENDUM:',
            style: 'paragraph',
            bold: true,
            underline: true
          },
          {
            text: '\nThe following shall be mentioned in this addendum:\n\n',
            style: 'paragraph'
          },
          {
            ol: formContent['formContractType2Remarks'].split('\n\n'),
            style: 'paragraph',
            margin: [20, 0, 0, 0]
          },
          {
            text: [
              '\nThe remaining clauses remain unchanged',
              '\n\nConcluded today, on the ' + this.utils.getOrElse(formContent['formContractType2ContractDate']) + '\n\n'
            ],
            style: 'paragraph'
          },
          {
            columns: [
              [{text: 'SHIPPING HOUSE'}],
              [{text: 'CARRIER', aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,20,0,0]
          },
          {
            columns: [
              [{text: 'SC C.M.D IRIS TRUCK SRL'}],
              [{text: this.utils.clientFormatName(client), aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: `${this.currentUser.firstName} ${this.currentUser.lastName}`.toUpperCase()}],
              [{text: client.representative, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'Transport Coordinator'}],
              [{text: client.type==='PJ'? 'Representative' : '', aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'Mobile: ' + this.currentUser.phone}],
              [{text: 'Mobile: ' + client.phone, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'E-mail: ' + this.currentUser.email}],
              [{text: 'E-mail: ' + client.email, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            image: this.utils.signature(this.currentUser.id),
            width: 150
          }    
        ],
        styles: {
          title: {
            bold: true,
            fontSize: 12,
            alignment: 'center'
          },
          paragraph: {
            fontSize: 11
          }
        }
      };


      return pdfMake.createPdf(docDefinition).download("act_aditional_transportator_nr_" + this.utils.getOrElse(formContent['formContractType2ContractNumber']));
    });
  }

  contractType3RO(formContent: any, id: number) {
    this.clientsService.getClient(id).subscribe( client => {
      let docDefinition = {
        content: [
          {
            text: 'COMANDĂ DE TRANSPORT',
            style: 'title'
          },
          {
            text: '\nNr. ' + this.utils.getOrElse(formContent['formContractType3ContractNumber']) + ' din ' + this.utils.getOrElse(formContent['formContractType3ContractFrom']),
            style: 'paragraph',
            alignment: 'center'
          },
          {
            text: '\n\n\nCap. I PĂRȚILE COMENZII',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nSC C.M.D. IRIS TRUCK SRL', bold: true},
              ', cu sediul social în Municipiul Sibiu, Strada VASILE AARON, Nr. 22, Bloc 80, Scara E, Etaj 5, Ap. M4 (72), Județ Sibiu, Înregistrată la Registrul Comerțului sub număr J32/389/01.03.2021, având CUI RO 43821263, având cont bancar deschis la Banca ING Sibiu, reprezentată legal prin ' + `${this.currentUser.firstName} ${this.currentUser.lastName}`.toUpperCase() + ' - coordonator transport, telefon ' + this.currentUser.phone + ', e-mail ' + this.currentUser.email + ', în calitate de CASĂ DE EXPEDIȚII.'],
            style: 'paragraph'
          },
          {
            text: [
              '\nȘI\n\n',
              this.utils.clientFullTextRO(client),
              {text: 'TRANSPORTATOR.', bold: true}
            ],
            style: 'paragraph'
          },
          {
            text: '\nCap. II OBIECTUL COMENZII',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 2.1. ', bold: true},
              'Casa de expediții a angajat Transportatorul ca și cărăuș în vederea organizării serviciilor de transport rutier, după cum urmează. '
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nRută: ' + this.utils.getOrElse(formContent['formContractType3TransportRoute'])},
              {text: '\nTip marfă: ' + this.utils.getOrElse(formContent['formContractType3TransportType'])}
            ],
            style: 'paragraph',
            bold: true
          },
          {
            text: this.utils.getOrElse(formContent['formContractType3TransportUploadInfo']),
            style: 'paragraph',
            bold: true,
            margin: [ 40, 10, 0, 0 ]
          },
          {
            text: this.utils.getOrElse(formContent['formContractType3TransportDownloadInfo']),
            style: 'paragraph',
            bold: true,
            margin: [ 40, 20, 0, 15 ]
          },
          {
            text: [
              '\nTransportatorul va proceda la descărcarea mărfii numai în baza confirmării scrise comunicată de ',
              {text: 'SC C.M.D. IRIS TRUCK SRL', bold: true},
              '. În caz contrar, nu ne asumăm responsabilitatea plății transportului către dvs.'
            ],
            style: 'paragraph',
          },
          {
            text: [
              '\nValoare marfă: ' + this.utils.getOrElse(formContent['formContractType3TransportMerchandiseValue']),
              '\nTip vehicul: ' + this.utils.getOrElse(formContent['formContractType3TransportVehicleType']),
              {text: `, transport rutier, în regim ${this.utils.getOrElse(formContent['formContractType3TransportVehicleRegime']) === 'GR' ? 'grupaj' : 'exclusiv'}.`, bold: false},
              '\nNumăr auto: ' + this.utils.getOrElse(formContent['formContractType3TransportCarNumber']),
              '\nAsigurare CMR: ' + this.utils.getOrElse(formContent['formContractType3TransportCRMInsurance'])
            ],
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\n\nArt. 2.2. ', bold: true},
              'Transportatorul este obligat să menționeze în cazul în care transportul nu va fi efectuat cu mijloace proprii alături de numărul camionului.'
            ],
            style: 'paragraph'
          },
          ,
          {
            text: [
              {text: '\n\nArt. 2.3. ', bold: true},
              'Marfa încărcată pentru casa de expediții poate fi transbordată doar cu acordul acesteia.'
            ],
            style: 'paragraph'
          }
          ,
          {
            text: [
              {text: '\n\nArt. 2.4. ', bold: true},
              'Nerespectarea de către transportator a clauzei de exclusivitate sau a oricăreia din detaliile privind obiectul comenzii atrage după sine penalizări echivalente cu valoarea transportului.'
            ],
            style: 'paragraph'
          },
          {
            text: '\n\nCap. III DREPTURILE ȘI OBLIGAȚIILE PĂRȚILOR PRIVIND SERVICIUL DE TRANSPORT',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 3.1. ', bold: true},
              'Transportatorul se obligă să asigure mijlocul de transport adecvat, a cărui capacitate este suficientă și în stare corespunzătoare, la data și ora fixată, conform',
              {text: ' Cap. II ', bold: true},
              'și în condițiile stabilite de comun acord. Întârzierea camionului se penalizează cu 150 EUR/zi întârziere.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.2. ', bold: true},
              'Dacă autovehiculul nu corespunde cerințelor expeditorului, prezentând defecțiuni tehnice, nu este propriu naturii mărfii sau nu are dotările necesare, acesta este în drept să ceară înlocuirea cu un altul corespunzător.'
            ],
            style: 'paragraph'
          },
          {
            text: '\nArt. 3.3. Încărcarea și descărcarea mărfii',
            bold: true,
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.1. ', bold: true},
              'Să ia în primire marfa.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.2. ', bold: true},
              'Să întocmească după primirea mărfii, documentele de transport.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.3. ', bold: true},
              'Transportatorul are obligația să verifice exactitatea documentelor puse la dispoziție de către expeditor precum și integritatea lor. Conducătorul auto nu va pleca de la locul încărcării fără toate documentele ce trebuie să însoțească transportul în vederea vămuirii mărfii fără probleme.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.4. ', bold: true},
              'Conducătorul auto trebuie să supravegheze încărcarea și descărcarea mijlocului de transport atât la locul de încărcare al beneficiarului cât și la locul de descărcare. Drepturile și obligațiile transportatorului în privința încărcării și descărcării mărfurilor sunt conforme cu reglementările acordului CMR, ale acordului TIR, precum și cu celelalte reglementări ale legislației de transport internaționale și naționale.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.5. ', bold: true},
              'Transportatorul se obligă să îndeplinească oricând condițiile legale internaționale (CMR, TIR, AETR, ADR, etc) pentru executarea comenzilor primite de la Beneficiar. Totodată, Transportatorul se obligă să procure toate permisele și autorizațiile necesare pentru transport, corespunzător legislației naționale și a celei internaționale a statelor pe teritoriul cărora se efectuează transporturile.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.6. ', bold: true},
              'Transportatorul trebuie să suporte contravaloarea eventualelor lipsuri sau pierderi înregistrate, produse din culpa sa.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.7. ', bold: true},
              'La primirea mărfii, transportatorul este obligat să verifice exactitatea mențiunilor din CMR referitoare la numărul de colete, la marcajul și numerele lor, cât și la starea aparentă a mărfii și a ambalajului ei.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.8. ', bold: true},
              'Neprezentarea camionului la încărcare sau prezentarea camionului  la încărcare în condiții necorespunzătoare ( murdar, prelata/ podea ruptă, etc, în caz de grupaj, marfă încărcată necorespunzător), duce la penalizări echivalente cu valoarea comenzii de transport.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.9. ', bold: true},
              'Transportatorul are obligația să anunțe în scris casa de expediții cu minim 24 de ore înainte de descărcarea mărfii și să nu descarce marfa până nu primește aprobare scrisă din partea casei de expediții. În caz contrar și dacă casa de expediții nu a încasat contravaloarea transportului de la clientul ei ( cel care a comandat transportul), casa de expediție este scutită de plata transportului către transportator.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.10. ', bold: true},
              'Transportatorul este răspunzător pentru deținerea și modul de întocmire/ completare a tuturor documentelor necesare transportului ( asigurare, asigurare CMR, licențe, autorizații etc.), neputând ridica nicio pretenție față de casa de expediții cu privire la orice abateri legate de deținerea și completarea documentelor.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.11. ', bold: true},
              'Camionul este liber pentru încărcare/ descărcare 24 de ore.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.4. ', bold: true},
              'Casa de expediții are obligația ca detaliile prevăzute în',
              {text: ' Cap. II ', bold: true},
              'să fie cât mai exacte, detalii care sunt preluate de către casa de expediții de la clientul acesteia.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.5. ', bold: true},
              'Transportatorul poartă răspunderea asupra integrității mărfii și este răspunzător pentru pierderea totală, parțială sau pentru avarie produse între momentul primirii mărfii, cât și pentru  întârzierea în eliberare, orice daună produsă în conformitate cu prevederile legale, iar orice lipsă, deteriorare, spargere  sau furt vor fi  suportate integral de către transportator.'
            ],
            style: 'paragraph'
          },
          {
            text: '\nCap.IV PREȚ ȘI MODALITĂȚI DE PLATĂ',
            style: 'paragraph',
            bold: true
          },
          {
            text: '\nArt. 4.1 Prețul transportului agreat de ambele părți este ' + this.utils.getOrElse(formContent['formContractType3TransportPrice']),
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              '\nArt. 4.2 Plata de către Beneficiar se va face în termen de ',
              this.utils.getOrElse(formContent['formContractType3TransportPaymentTerm']),
              ' zile de la data primirii de la Transportator a facturilor însoțite de CMR-ul confirmat și alte documente însoțitoare dacă este cazul.'],
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 4.3. ', bold: true},
              'Factura de transport  va fi emisă de către transportator în ziua descărcării mărfii, în lei la cursul oficial BNR, fiind menționată obligatoriu și valoarea în Euro. Pe factura de transport se va menționa adresa sediului social casei de expediții, urmând ca documentele să fie trimise la adresa punctului de lucru: Strada Vasile Aaron, Nr. 22., Bl. 80, Sc. E, Et. 5, Ap. M4 ( 72), Sibiu, Jud. Sibiu.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 4.4.', bold: true},
              'Prețul menționat la',
              {text: ' Art. 4.1 ', bold: true},
              'poate fi modificat numai prin act adițional între cele două părți în urma modificării oricăreia dintre detaliile menționate la',
              {text: ' Art. 2.1.', bold: true}
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 4.5. ', bold: true},
              'În cazul în care transportul implică deschiderea documentului de tranzit T1, costurile suplimentare achitate de către transportator se vor adăuga prețului menționat la',
              {text: ' Art. 4.1', bold: true},
              ', în baza documentelor justificative.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 4.6. ', bold: true},
              'În cazul în care casa de expediții achită eronat și în plus sume către transportator, acesta este obligat să restituie sumele încasate necuvenit în termen de 10 zile de la încasarea lor.'
            ],
            style: 'paragraph'
          },
          {
            text: '\nCap. V ÎNCETAREA ȘI REZILIEREA COMENZII',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 5.1. ', bold: true},
              'În cazul în care transportatorul nu respectă detaliile comenzii, casa de expediții are dreptul de a anula comanda, transportatorul având obligația de a plăti o penalizare egală cu contravaloarea comenzii de transport.'
            ],
            style: 'paragraph'
          },        
          {
            text: [
              {text: '\nArt. 5.2. ', bold: true},
              'După acceptarea comenzii, în cazul în care transportatorul nu răspunde solicitării casei de expediții de confirmare a datelor legate de efectuarea și organizarea transportului în termenul prezentat la solicitarea comunicată prin e-mail casa de expediții este îndreptățită să denunțe unilateral comanda, notificând în acest sens transportatorul. În acest caz, casa de expediții nu este răspunzătoare de nicio cheltuială pe care transportatorul ar fi făcut-o pentru transportul respectiv, neputând fi obligată la despăgubiri.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 5.3. ', bold: true},
              'După acceptarea comenzii, transportatorul nu poate să denunțe unilateral comanda în mod nejustificat. În caz contrar, acesta are obligația de a plăti o penalizare egală cu contravaloarea comenzii de transport în termen de 24 de ore de la data denunțării.'
            ],
            style: 'paragraph'
          },       
          {
            text: '\n\nCap.VI. Confidențialitate',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 6.1. ', bold: true},
              'Orice informație care face obiectul acestui contract este considerată confidențială.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 6.2. ', bold: true},
              'Contactarea directă a clientului casei de expediții, precum și divulgarea de informații din această comandă, fără acordul casei de expediții, conduce la penalizări în valoare de 5500 EURO.'
            ],
            style: 'paragraph'
          },
          {
            text: '\nCap.VII. LITIGII',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 7.1. ', bold: true},
              'Neînțelegere dintre părțile contractante în legătură cu clauzele și executarea prezentei comenzi  se vor soluționa pe cale amiabilă, dacă acest lucru nu este posibil va fi sesizată instanța judecătorească competentă.'
            ],
            style: 'paragraph'
          },
          {
            text: '\nCap.VIII CLAUZE FINALE',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 8.1. ', bold: true},
              'Orice modificare sau completare a prezentei comenzi se va face în baza unui act adițional care va exprima acordul expres al ambelor părți.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 8.2. ', bold: true},
              'Prezenta comandă, cuprinzând condițiile generale și pe cele speciale de transport reprezintă singura înțelegere valabilă între părți.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 8.3. ', bold: true},
              'Comanda se consideră acceptată de către transportator, în integritatea sa, fără ștampila și semnătura transportatorului, în condițiile în care a fost comunicat acestuia, la adresa de e-mail indicată în partea introductivă a comenzii, iar transportatorul nu notifică în scris anularea transportului, după expirarea unui termen de o oră de la comunicare.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 8.4. ', bold: true},
              'Prezenta comandă a fost încheiată la data de ' + this.utils.getOrElse(formContent['formContractType3ContractFrom']) + ', câte un exemplar pentru fiecare parte contractantă și intră în vigoare la această dată.'
            ],
            style: 'paragraph'
          },
          {
            columns: [
              [{text: 'CASĂ DE EXPEDIȚII'}],
              [{text: 'TRANSPORTATOR', aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,20,0,0]
          },
          {
            columns: [
              [{text: 'SC C.M.D IRIS TRUCK SRL'}],
              [{text: this.utils.clientFormatName(client), aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: `${this.currentUser.firstName} ${this.currentUser.lastName}`.toUpperCase()}],
              [{text: client.representative, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'Coordonator Transport'}],
              [{text: client.type==='PJ'? 'Reprezentant' : '', aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'Mobil: ' + this.currentUser.phone}],
              [{text: 'Mobil: ' + client.phone, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'E-mail: ' + this.currentUser.email}],
              [{text: 'E-mail: ' + client.email, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            image: this.utils.signature(this.currentUser.id),
            width: 150
          }    
        ],
        styles: {
          title: {
            bold: true,
            fontSize: 12,
            alignment: 'center'
          },
          paragraph: {
            fontSize: 11
          }
        }
      };

      return pdfMake.createPdf(docDefinition).download("comanda_de_transport_nr_" + this.utils.getOrElse(formContent['formContractType3ContractNumber']));
    });
  }

  contractType3EN(formContent: any, id: number) {
    this.clientsService.getClient(id).subscribe( client => {
      let docDefinition = {
        content: [
          {
            text: 'SHIPPING ORDER',
            style: 'title'
          },
          {
            text: '\nNo. ' + this.utils.getOrElse(formContent['formContractType3ContractNumber']) + ' from ' + this.utils.getOrElse(formContent['formContractType3ContractFrom']),
            style: 'paragraph',
            alignment: 'center'
          },
          {
            text: '\n\n\nChapter I PARTIES OF THE ORDER',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nSC C.M.D. IRIS TRUCK SRL', bold: true},
              ', with Registered office in Sibiu, Vasile ARON Street, No. 22, Block 80, Entrance E, Floor 5, Ap. M4 (72), Sibiu County, registered at the Trade Register under number J32/389/01.03.2021, with CUI RO 43821263, with bank account opened at Banca ing Sibiu, legally represented by ' + `${this.currentUser.firstName} ${this.currentUser.lastName}`.toUpperCase() + ' - transport coordinator, phone ' + this.currentUser.phone + ', e-mail ' + this.currentUser.email + ', as a Shipping House.'],
            style: 'paragraph'
          },
          {
            text: [
              '\nAnd\n\n',
              this.utils.clientFullTextEN(client),
              {text: 'CARRIER.', bold: true}
            ],
            style: 'paragraph'
          },
          {
            text: '\nChapter II OBJECT OF THE ORDER',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 2.1. ', bold: true},
              'The shipping House hired the carrier as a carrier in order to organize road transport services as follows. '
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nRoute: ' + this.utils.getOrElse(formContent['formContractType3TransportRoute'])},
              {text: '\nCargo type: ' + this.utils.getOrElse(formContent['formContractType3TransportType'])}
            ],
            style: 'paragraph',
            bold: true
          },
          {
            text: this.utils.getOrElse(formContent['formContractType3TransportUploadInfo']),
            style: 'paragraph',
            bold: true,
            margin: [ 40, 10, 0, 0 ]
          },
          {
            text: this.utils.getOrElse(formContent['formContractType3TransportDownloadInfo']),
            style: 'paragraph',
            bold: true,
            margin: [ 40, 20, 0, 15 ]
          },
          {
            text: [
              '\nThe carrier will unload the goods only based on the written confirmation communicated by ',
              {text: 'SC C.M.D. IRIS TRUCK SRL', bold: true},
              '. Otherwise, we will not be responsible for paying for shipping to you.'
            ],
            style: 'paragraph',
          },
          {
            text: [
              '\nCargo value: ' + this.utils.getOrElse(formContent['formContractType3TransportMerchandiseValue']),
              '\nVehicle type: ' + this.utils.getOrElse(formContent['formContractType3TransportVehicleType']),
              {text: `, road transport, ${this.utils.getOrElse(formContent['formContractType3TransportVehicleRegime']) === 'GR' ? 'groupage' : 'exclusive'}.`, bold: false},
              '\nCar number: ' + this.utils.getOrElse(formContent['formContractType3TransportCarNumber']),
              '\nCMR insurance: ' + this.utils.getOrElse(formContent['formContractType3TransportCRMInsurance'])
            ],
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\n\nArt. 2.2. ', bold: true},
              'The carrier must indicate if the transport will not be carried out by its own means and the transporting truck number.'
            ],
            style: 'paragraph'
          },
          ,
          {
            text: [
              {text: '\n\nArt. 2.3. ', bold: true},
              'The cargo loaded for the Shipping House can only be transhipped with its consent.'
            ],
            style: 'paragraph'
          }
          ,
          {
            text: [
              {text: '\n\nArt. 2.4. ', bold: true},
              'Failure by the carrier to comply with the exclusivity clause or any of the details concerning the object of the order entails penalties equivalent to the value of the transport.'
            ],
            style: 'paragraph'
          },
          {
            text: '\n\nChapter III RIGHTS AND OBLIGATIONS OF THE PARTIES CONCERNING THE TRANSPORT SERVICE',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 3.1. ', bold: true},
              'The carrier undertakes to provide the appropriate means of transport, the capacity of which is sufficient and in the appropriate condition, at the date and time established in accordance with',
              {text: ' Chapter II ', bold: true},
              'and under mutually agreed conditions. The delay of the truck is penalized with 150 EUR / delay day.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.2. ', bold: true},
              'If the vehicle does not meet the requirements of the consignor, showing technical defects, is not proper to the nature of the goods or does not have the necessary equipment, he is entitled to request replacement with an appropriate one.'
            ],
            style: 'paragraph'
          },
          {
            text: '\nArt. 3.3. Loading and unloading of goods',
            bold: true,
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.1. ', bold: true},
              'To take over the goods.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.2. ', bold: true},
              'To draw up after receipt of the goods, the transport documents.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.3. ', bold: true},
              'The carrier must verify the accuracy of the documents provided by the shipper and their integrity. The driver shall not leave the place of loading without all the documents to accompany the transport in order to make customs clearance of the goods without any problems.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.4. ', bold: true},
              'The driver must supervise the loading and unloading of the means of transport both at the place of loading of the beneficiary and at the place of unloading. The rights and obligations of the carrier with regard to loading and unloading goods are in accordance with the regulations of the CMR agreement, the TIR agreement, as well as the other regulations of international and national transport legislation.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.5. ', bold: true},
              'The carrier undertakes to comply at any time with the international legal conditions (CMR, TIR, AETR, ADR, etc.) for the execution of orders received from the beneficiary. At the same time, the carrier undertakes to procure all the necessary permits and authorizations for transport, corresponding to the national and international legislation of the states in whose territory the transports are carried out.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.6. ', bold: true},
              'The carrier must bear the value of any lacks or losses incurred as a result of its fault.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.7. ', bold: true},
              'Upon receipt of the goods, the carrier shall verify the accuracy of the entries in the CMR relating to the number of packages, their marking and numbers, and the apparent condition of the goods and its packaging.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.8. ', bold: true},
              'Failure to present the truck to load or presenting the truck to load in improper conditions (dirty, tarp/ broken floor, etc., in case of grouping, improperly loaded cargo), leads to penalties equivalent to the value of the transport order.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.9. ', bold: true},
              'The carrier is obliged to notify the shipping house in writing at least 24 hours before unloading the goods and not to unload the goods until it receives written approval from the Shipping House. Otherwise, and if the Shipping House has not collected the value of the shipment from its customer ( the one who ordered the shipment), the Shipping House is exempt from paying the shipment to the carrier.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.10. ', bold: true},
              'The Carrier shall be responsible for the ownership and the mode of preparation/ completion of all the documents necessary for transportation ( insurance, CMR insurance,  licenses, permits, etc.), and cannot raise any claims against the Shipping House, with respect to any violation relating to the possession and completion of the documents.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3.11. ', bold: true},
              'The truck is free for loading/ unloading for 24 hours.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.4. ', bold: true},
              'The Shipping House must make sure that the details laid down in',
              {text: ' Chapter II ', bold: true},
              'are as accurate as possible, details that are taken by the shipping house from its customer.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.5. ', bold: true},
              'The carrier shall be liable for the integrity of the goods and shall be liable for the total, partial or damage caused between the time of receipt of the goods and for the delay in release, any damage caused in accordance with the legal provisions, and any missing, damage, break-in or theft shall be borne in full by the carrier.'
            ],
            style: 'paragraph'
          },
          {
            text: '\nChapter IV PRICE AND PAYMENT METHODS',
            style: 'paragraph',
            bold: true
          },
          {
            text: '\nArt. 4.1 The price of Transport agreed by both parties is ' + this.utils.getOrElse(formContent['formContractType3TransportPrice']),
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              '\nArt. 4.2 Payment by the beneficiary shall be made within ',
              this.utils.getOrElse(formContent['formContractType3TransportPaymentTerm']),
              ' days from the date of receipt from the carrier of the invoices accompanied by the confirmed CMR and other accompanying documents if applicable.'],
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 4.3. ', bold: true},
              'The Transport invoice will be issued by the carrier on the day of unloading the goods, in lei at the official NBR rate, and the value in euros must be mentioned. On the transport invoice, the address of the Registered office of the Shipping House will be mentioned, and the documents will be sent to the address of the Working point: Vasile Aaron Street, No. 22., Bl. 80, Sc. E, Et. 5, Ap. M4 (72), Sibiu, County Sibiu.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 4.4.', bold: true},
              'The Price referred to in',
              {text: ' Art. 4.1 ', bold: true},
              'can be modified only by an addendum concluded between the two parties following the modification of any of the details referred to in',
              {text: ' Art. 2.1.', bold: true}
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 4.5. ', bold: true},
              'If the transport involves the opening of the T1 transit document, the additional costs paid by the carrier will be added to the price referred to in',
              {text: ' Art. 4.1', bold: true},
              ', based on supporting documents.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 4.6. ', bold: true},
              'In the event that the Shipping House pays erroneously and in addition amounts to the carrier, the carrier is obliged to refund the amounts improperly collected within 10 days of their receipt.'
            ],
            style: 'paragraph'
          },
          {
            text: '\nChapter V TERMINATION OF THE ORDER',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 5.1. ', bold: true},
              'If the carrier does not comply with the order details, the shipping company has the right to cancel the order, the carrier having the obligation to pay a penalty equal to the value of the transport order.'
            ],
            style: 'paragraph'
          },        
          {
            text: [
              {text: '\nArt. 5.2. ', bold: true},
              'After acceptance of the order, if the carrier does not respond to the request of the shipping company for confirmation of the data related to the carrying out and organization of the transport within the deadline presented at the request communicated by e-mail, the shipping company is entitled to unilaterally denounce the order, notifying the carrier accordingly. In that case, the Shipping House shall not be liable for any expense that the carrier would have made for that transport and shall not be liable for compensation.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 5.3. ', bold: true},
              'After acceptance of the order, the carrier may not unilaterally terminate the order unreasonably. Otherwise, he is obliged to pay a penalty equal to the value of the transport order within 24 hours from the date of denunciation.'
            ],
            style: 'paragraph'
          },       
          {
            text: '\n\nChapter VI. Confidentiality',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 6.1. ', bold: true},
              'Any information that makes the object of this contract is considered confidential.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 6.2. ', bold: true},
              'Direct contact of the client of the Shipping House, as well as disclosure of information from this order, without the consent of the shipping house, leads to penalties in the amount of 5500 euros.'
            ],
            style: 'paragraph'
          },
          {
            text: '\nChapter VII. Disputes',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 7.1. ', bold: true},
              'Disagreements between the Contracting Parties in relation to the terms and execution of this order shall be resolved amicably, if this is not possible, the competent court shall be seised.'
            ],
            style: 'paragraph'
          },
          {
            text: '\nChapter VIII. FINAL PROVISION',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 8.1. ', bold: true},
              'Any modification or addition to this order will be made on the basis of an addendum that will express the express agreement of both parties.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 8.2. ', bold: true},
              'This order, including the general and special conditions of carriage, is the only valid agreement between the parties.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 8.3. ', bold: true},
              'The order shall be deemed to be accepted by the carrier, in its integrity, without the carrier`s stamp and signature, provided that it has been communicated to the carrier, at the e-mail address indicated in the introductory part of the order, and the carrier shall not notify the cancellation of the consignment in writing after the expiry of a period of one hour following the communication.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 8.4. ', bold: true},
              'This order was concluded on the ' + this.utils.getOrElse(formContent['formContractType3ContractFrom']) + ', one copy for each contracting party and shall enter into force on this date.'
            ],
            style: 'paragraph'
          },
          {
            columns: [
              [{text: 'SHIPPING HOUSE'}],
              [{text: 'CARRIER', aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,20,0,0]
          },
          {
            columns: [
              [{text: 'SC C.M.D IRIS TRUCK SRL'}],
              [{text: this.utils.clientFormatName(client), aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: `${this.currentUser.firstName} ${this.currentUser.lastName}`.toUpperCase()}],
              [{text: client.representative, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'Transport Coordinator'}],
              [{text: client.type==='PJ'? 'Representative' : '', aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'Mobile: ' + this.currentUser.phone}],
              [{text: 'Mobile: ' + client.phone, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'E-mail: ' + this.currentUser.email}],
              [{text: 'E-mail: ' + client.email, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            image: this.utils.signature(this.currentUser.id),
            width: 150
          }    
        ],
        styles: {
          title: {
            bold: true,
            fontSize: 12,
            alignment: 'center'
          },
          paragraph: {
            fontSize: 11
          }
        }
      };

      return pdfMake.createPdf(docDefinition).download("comanda_de_transport_nr_" + this.utils.getOrElse(formContent['formContractType3ContractNumber']));
    });
  }  

  contractType4RO(formContent: any, id: number) {
    this.clientsService.getClient(id).subscribe( client => {
      let docDefinition = {
        content: [
          {
            text: 'CONTRACT DE TRANSPORT',
            style: 'title'
          },
          {
            text: '\nNr. ' + this.utils.getOrElse(formContent['formContractType4ContractNumber']) + ' din ' + this.utils.getOrElse(formContent['formContractType4ContractFrom']),
            style: 'paragraph',
            alignment: 'center'
          },
          {
            text: '\n\n\nCap. I PĂRȚILE CONTRACTANTE',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nSC C.M.D. IRIS TRUCK SRL', bold: true},
              ', cu sediul social în Municipiul Sibiu, Strada VASILE AARON, Nr. 22, Bloc 80, Scara E, Etaj 5, Ap. M4 (72), Județ Sibiu, Înregistrată la Registrul Comerțului sub număr J32/389/01.03.2021, având CUI RO 43821263, având cont bancar deschis la Banca ING Sibiu, reprezentată legal prin ' + `${this.currentUser.firstName} ${this.currentUser.lastName}`.toUpperCase() + ' - coordonator transport, telefon ' + this.currentUser.phone + ', e-mail ' + this.currentUser.email + ', în calitate de PRESTATOR.'],
            style: 'paragraph'
          },
          {
            text: [
              '\nȘI\n\n',
              this.utils.clientFullTextRO(client),
              {text: 'CLIENT.', bold: true}
            ],
            style: 'paragraph'
          },
          {
            text: '\nCap. II OBIECTUL CONTRACTULUI',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 2.1. ', bold: true},
              'Obiectul contractului îl constituie prestarea de servicii de transport marfă, de către PRESTATOR pentru CLIENT, în condițiile menționate în prezentul contract.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nRută: ' + this.utils.getOrElse(formContent['formContractType4TransportRoute'])},
              {text: '\nTip marfă: ' + this.utils.getOrElse(formContent['formContractType4TransportType'])}
            ],
            style: 'paragraph',
            bold: true
          },
          {
            text: this.utils.getOrElse(formContent['formContractType4TransportUploadInfo']),
            style: 'paragraph',
            bold: true,
            margin: [ 40, 10, 0, 0 ]
          },
          {
            text: this.utils.getOrElse(formContent['formContractType4TransportDownloadInfo']),
            style: 'paragraph',
            bold: true,
            margin: [ 40, 20, 0, 15 ]
          },
          {
            text: [
              'Valoare marfă: ' + this.utils.getOrElse(formContent['formContractType4TransportMerchandiseValue']),
              '\nTip vehicul: ' + this.utils.getOrElse(formContent['formContractType4TransportVehicleType']),
              {text: `, transport rutier, în regim ${this.utils.getOrElse(formContent['formContractType4TransportVehicleRegime']) === 'GR' ? 'grupaj' : 'exclusiv'}.`, bold: false},
              '\nNumăr auto: ' + this.utils.getOrElse(formContent['formContractType4TransportCarNumber']),
              '\nAsigurare CMR: ' + this.utils.getOrElse(formContent['formContractType4TransportCRMInsurance']),
              '\nPreț transport: ' + this.utils.getOrElse(formContent['formContractType4TransportTPrice']) 
            ],
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\n\nArt. 2.2. ', bold: true},
              'Modalitate de plată.'
            ],
            style: 'paragraph'
          },
          {
            text: this.utils.getOrElse(formContent['formContractType4PaymentMethods']),
            bold: true,
            style: 'paragraph'
          },
          {
            text: '\n\nCap. III DREPTURILE ȘI OBLIGAȚIILE AMBELOR PĂRȚI PRIVIND SERVICIUL DE TRANSPORT',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 3.1. ', bold: true},
              'Prestatorul se obligă să asigure mijlocul de transport adecvat, a cărui capacitate este suficientă și în stare corespunzătoare, la data și ora fixată, conform comenzilor ferme ale Beneficiarului și în condițiile stabilite de comun acord.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.2. ', bold: true},
              'Nu se acceptă staționarea mașinii mai mult de 3 ore la locul de încărcare/descărcare pentru încărcări la grupaj. Prestatorul are dreptul de a rezilia prezentul contract și de a cere plata unei penalizări, de 100 EUR + TVA.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3. ', bold: true},
              'Adresele de încărcare/descărcare ( precum și drumul de acces către acestea ) sunt menționate de client astfel încât accesul camionului să nu fie restricționat/ sau să fie necesară plata de taxe suplimentare. Dacă este necesară achitarea de taxe suplimentare pentru acces în zona respectivă, clientul are obligația de a suporta aceste taxe.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.4. ', bold: true},
              'Marfa trebuie să fie predată, ambalată și etichetată conform contractului de transport, astfel încât să reziste operațiunilor de transport. PRESTATORUL nu răspunde pentru daunele ce pot apărea în cazul în care marfa nu este ambalată corespunzător.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.5. ', bold: true},
              'În cazul încărcării excesive a camionului peste tonajul înscris în contract sau a depășirii dimensiunilor mărfii, PRESTATORUL are dreptul de a cere descărcarea surplusului de marfă până la tonajul/dimensiunile înscrise în contract. În cazul în care PRESTATORUL poate încărca și surplusul de marfă, clientul va plăti un preț suplimentar pentru marfa încărcată.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.6. ', bold: true},
              'Dacă la destinație se constată pierderi, avarii sau orice pagube produse mărfii, PRESTATORUL va fi contactat înainte de descărcarea mărfii pentru a lua măsurile necesare.\n\nLa locul de descărcare, marfa va fi fotografiată în camion, iar eventualele daune vor fi menționate pe CMR.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.7. ', bold: true},
              'În cazul în care transportul este supus formalităților vamale de import în UE CLIENTUL este obligat să pună la dispoziția PRESTATORULUI o copie de pe declarația vamală de import, care să includă și valoarea transportului în vamă.'
            ],
            style: 'paragraph'
          },
          {
            text: '\nCap. IV PREȚUL TRANSPORTULUI',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 4.1. ', bold: true},
              'Plata facturilor se efectuează în contul PRESTATORULUI, iar efectuarea plăților se va confirma PRESTATORULUI.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 4.2. ', bold: true},
              'În cazul în care plătitorul nu respectă termenul de plată, va suporta penalități de 1% din valoarea transportului/ zi de întârziere.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 4.3. ', bold: true},
              'În cazul în care factura emisă de prestator nu este achitată de către plătitorul prevăzut în contract, plata facturii cade în contul clientului.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 4.4. ', bold: true},
              'În cazul în care clientul sau plătitorul transportului au facturi în sold către prestator, prestatorul are dreptul de retenție asupra mărfurilor transportate.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 4.5. ', bold: true},
              'Clientul sau plătitorul transportului va restitui prestatorului toate cheltuielile pe care acesta le-a făcut în vederea păstrării mărfurilor. Dacă prestatorul nu a încasat sumele restante în termen de 4 zile de la data retenției mărfurilor, acesta are dreptul de a valorifica mărfurile reținute în vederea acoperirii totale sau parțiale a datoriei clientului/ plătitorului transportului.'
            ],
            style: 'paragraph'
          },
          {
            text: '\nCap. V RĂSPUNDEREA CONTRACTUALĂ',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 5.1. ', bold: true},
              'Răspunderea CLIENTULUI și a PLĂTITORULUI în contractul de transport.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 5.1.1 ', bold: true},
              'Clientul va răspunde pentru plata la termenul convenit în ',
              {text: 'Art.2.2', bold: true},
              ' a contravalorii transportului, orice întarziere ducând la plata de penalități.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 5.1.2 ', bold: true},
              'Răspunderea clientului poate interveni și în caz de deteriorare a autovehiculului cu ocazia încărcării sau pentru ambalarea necorespunzătoare și nefixarea mărfii corespunzător.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 5.1.3 ', bold: true},
              'Clientul își asumă responsabilitatea pentru orice consecințe nefavorabile rezultate ca urmare a unor date incorecte sau incomplete privind comanda de transport cât și documentele însoțitoare ale mărfii.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 5.2. ', bold: true},
              'Răspunderea prestatorului'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 5.2.1 ', bold: true},
              'Societatea de transport cotractată de prestator, poartă răspunderea asupra integrității mărfii pe toată durata transportului, iar toate daunele posibile vor fi suportate integral de transportator.'
            ],
            style: 'paragraph'
          },          
          {
            text: [
              {text: '\nArt. 5.2.2 ', bold: true},
              'Prestatorul răspunde în caz de nerespectare a obligațiilor stabilite în prezentul contract.'
            ],
            style: 'paragraph'
          },
          {
            text: '\n\nCap.VI. Confidențialitate',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 6.1. ', bold: true},
              'Orice informație care face obiectul acestui contract este considerată confidențială.'
            ],
            style: 'paragraph'
          },
          {
            text: '\nCap.VII. LITIGII',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 7.1. ', bold: true},
              'Neînțelegere dintre părțile contractante în legatură cu clauzele și executarea prezentului contract se vor soluționa pe cale amiabilă, dacă acest lucru nu este posibil va fi sesizată instanța judecatorească competentă.'
            ],
            style: 'paragraph'
          },
          {
            text: '\nCap.VIII CLAUZE FINALE',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 8.1. ', bold: true},
              'Orice modificare sau completare a prezentului contract se va face în baza unui act adițional care va exprima acordul expres al ambelor părți.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 8.2. ', bold: true},
              'Rezilierea prezentului contract se va putea produce cu acordul scris al ambelor părți sau în mod unilateral, în caz de neplată în termenul prevăzut sau prin voința exprimată în scris de una din părți.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 8.3. ', bold: true},
              'Prestatorul are dreptul de a rezilia unilateral contractul de transport datorită nerespectării/ schimbării datelor înscrise în contract la ',
              {text: 'Art. 2.1.', bold: true},
              ' Prin urmare, clientul este obligat să plătească o penalizare egală cu valoarea contractului de transport în termen de 24 de ore de la data denunțării.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 8.4. ', bold: true},
              'Contractul se consideră acceptat de către client, în integritatea sa, fără ștampila și semnătura clientului, în condițiile în care a fost comunicat acestuia, la adresa de e-mail indicată în partea introductivă a contractului, iar clientul nu notifică în scris anularea transportului, după expirarea unui termen de o oră de la comunicare.'
            ],
            style: 'paragraph'
          },
          {
            columns: [
              [{text: 'PRESTATOR'}],
              [{text: 'CLIENT', aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,20,0,0]
          },
          {
            columns: [
              [{text: 'SC C.M.D IRIS TRUCK SRL'}],
              [{text: this.utils.clientFormatName(client), aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: `${this.currentUser.firstName} ${this.currentUser.lastName}`.toUpperCase()}],
              [{text: client.representative, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'Coordonator Transport'}],
              [{text: client.type==='PJ'? 'Reprezentant' : '', aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'Mobil: ' + this.currentUser.phone}],
              [{text: 'Mobil: ' + client.phone, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'E-mail: ' + this.currentUser.email}],
              [{text: 'E-mail: ' + client.email, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            image: this.utils.signature(this.currentUser.id),
            width: 150
          }    
        ],
        styles: {
          title: {
            bold: true,
            fontSize: 12,
            alignment: 'center'
          },
          paragraph: {
            fontSize: 11
          }
        }
      };

      return pdfMake.createPdf(docDefinition).download("contract_de_transport_nr_" + this.utils.getOrElse(formContent['formContractType4ContractNumber']));
    });
  }

  contractType4EN(formContent: any, id: number) {
    this.clientsService.getClient(id).subscribe( client => {
      let docDefinition = {
        content: [
          {
            text: 'CARRIAGE CONTRACT',
            style: 'title'
          },
          {
            text: '\nNo. ' + this.utils.getOrElse(formContent['formContractType4ContractNumber']) + ' from ' + this.utils.getOrElse(formContent['formContractType4ContractFrom']),
            style: 'paragraph',
            alignment: 'center'
          },
          {
            text: '\n\n\nChapter I CONTRACTING PARTIES',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nSC C.M.D. IRIS TRUCK SRL', bold: true},
              ', with Registered office in Sibiu, Vasile ARON Street, No. 22, Block 80, Entrance E, Floor 5, Ap. M4 (72), Sibiu County, registered at the Trade Register under number J32/389/01.03.2021, with CUI RO 43821263, with bank account opened at Banca ing Sibiu, legally represented by ' + `${this.currentUser.firstName} ${this.currentUser.lastName}`.toUpperCase() + ' - transport coordinator, phone ' + this.currentUser.phone + ', e-mail ' + this.currentUser.email + ', as a provider.'],
            style: 'paragraph'
          },
          {
            text: [
              '\nAnd\n\n',
              this.utils.clientFullTextEN(client),
              {text: 'CUSTOMER.', bold: true}
            ],
            style: 'paragraph'
          },
          {
            text: '\nChapter II OBJECT OF THE CONTRACT',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 2.1. ', bold: true},
              'The object of the contract is the provision of freight transport services by the provider for the customer, under the conditions referred to in this contract.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nRoute: ' + this.utils.getOrElse(formContent['formContractType4TransportRoute'])},
              {text: '\nCargo type: ' + this.utils.getOrElse(formContent['formContractType4TransportType'])}
            ],
            style: 'paragraph',
            bold: true
          },
          {
            text: this.utils.getOrElse(formContent['formContractType4TransportUploadInfo']),
            style: 'paragraph',
            bold: true,
            margin: [ 40, 10, 0, 0 ]
          },
          {
            text: this.utils.getOrElse(formContent['formContractType4TransportDownloadInfo']),
            style: 'paragraph',
            bold: true,
            margin: [ 40, 20, 0, 15 ]
          },
          {
            text: [
              'Cargo value: ' + this.utils.getOrElse(formContent['formContractType4TransportMerchandiseValue']),
              '\nVehicle type: ' + this.utils.getOrElse(formContent['formContractType4TransportVehicleType']),
              {text: `, road transport, ${this.utils.getOrElse(formContent['formContractType4TransportVehicleRegime']) === 'GR' ? 'groupage' : 'exclusive'}.`, bold: false},
              '\nCar number: ' + this.utils.getOrElse(formContent['formContractType4TransportCarNumber']),
              '\nCMR insurance: ' + this.utils.getOrElse(formContent['formContractType4TransportCRMInsurance']),
              '\nTransport price: ' + this.utils.getOrElse(formContent['formContractType4TransportTPrice']) 
            ],
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\n\nArt. 2.2. ', bold: true},
              'Payment method.'
            ],
            style: 'paragraph'
          },
          {
            text: this.utils.getOrElse(formContent['formContractType4PaymentMethods']),
            bold: true,
            style: 'paragraph'
          },
          {
            text: '\n\nChapter III RIGHTS AND OBLIGATIONS OF BOTH PARTIES REGARDING THE TRANSPORT SERVICE',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 3.1. ', bold: true},
              'The provider undertakes to provide the appropriate means of transport, the capacity of which is sufficient and in the appropriate condition, at the fixed date and time, in accordance with the firm orders of the beneficiary and under the conditions established by mutual agreement.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.2. ', bold: true},
              'It is not allowed to station the machine for more than 3 hours at the loading/unloading place for grouping loads. The provider has the right to terminate this contract and demand payment of a penalty of EUR 100 + VAT.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.3. ', bold: true},
              'The loading/unloading addresses (as well as the access road to them ) are mentioned by the customer so that the access of the truck is not restricted/ or it won`t be necessary to pay additional taxes. If it is necessary to pay additional fees for access to the area, the customer is obliged to bear these fees.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.4. ', bold: true},
              'The goods must be delivered, packaged and labelled according to the contract of carriage so as to withstand transport operations. The provider is not liable for damages that may occur if the goods are not properly packed.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.5. ', bold: true},
              'In the case of excessive loading of the truck over the tonnage entered in the contract or exceeding the dimensions of the goods, the Provider shall have the right to request the unloading of the excess cargo up to the tonnage/dimensions entered in the contract. If the provider can also load the excess cargo, the customer will pay an additional price for the cargo loaded.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.6. ', bold: true},
              'If loss or any damage to the goods is found at the destination, the provider will be contacted before unloading the goods to take the necessary measures.\n\nAt the place of unloading, the goods will be photographed in the truck, and any damage will be mentioned on the CMR.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 3.7. ', bold: true},
              'If the consignment is subject to customs formalities for import into the EU, the customer is obliged to provide the supplier with a copy of the customs declaration of import, including the value of the consignment at customs.'
            ],
            style: 'paragraph'
          },
          {
            text: '\nChapter IV TRANSPORT PRICE',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 4.1. ', bold: true},
              'Payment of invoices is made to the provider`s account, and payment will be confirmed to the provider.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 4.2. ', bold: true},
              'If the payer does not meet the payment deadline, he will incur penalties of 1% of the value of the shipment/ day of delay.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 4.3. ', bold: true},
              'If the invoice issued by the provider is not paid by the payer provided for in the contract, the payment of the invoice falls on the customer`s account.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 4.4. ', bold: true},
              'If the customer or the payer of the transport has unpaid invoices to the provider, the provider has the right to retain the goods transported.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 4.5. ', bold: true},
              'The customer or the payer of the transport will refund to the provider all the expenses that he has made in order to keep the goods. If the provider has not collected the outstanding amounts within 4 days from the date of retention of the goods, it has the right to redeem the retained goods in order to cover the total or partial debt of the customer/ payer of the transport.'
            ],
            style: 'paragraph'
          },
          {
            text: '\nChapter V CONTRACTUAL LIABILITY',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 5.1. ', bold: true},
              'Liability of the customer and of the payer in the carriage contract.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 5.1.1 ', bold: true},
              'The client shall be liable for payment within the term agreed in ',
              {text: 'Art.2.2', bold: true},
              ' of the value of the transport, any delay resulting in the payment of penalties.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 5.1.2 ', bold: true},
              'The customer`s liability may also arise in the event of damage to the vehicle during loading or for improper packaging and failure to secure the goods properly.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 5.1.3 ', bold: true},
              'The customer assumes responsibility for any adverse consequences resulting from incorrect or incomplete data on the transport order and accompanying documents of the goods.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 5.2. ', bold: true},
              'Liability of the provider'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 5.2.1 ', bold: true},
              'The Transport Company contracted by the provider, bears responsibility for the integrity of the goods throughout the transport, and all possible damages will be borne in full by the carrier.'
            ],
            style: 'paragraph'
          },          
          {
            text: [
              {text: '\nArt. 5.2.2 ', bold: true},
              'The provider is liable in case of non-compliance with the obligations set out in this contract.'
            ],
            style: 'paragraph'
          },
          {
            text: '\n\nChapter VI. Confidentiality',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 6.1. ', bold: true},
              'Any information that makes the object of this contract is considered confidential.'
            ],
            style: 'paragraph'
          },
          {
            text: '\nChapter VII Disputes',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 7.1. ', bold: true},
              'Disagreements between the Contracting Parties in relation to the terms and execution of this order shall be resolved amicably, if this is not possible, the competent court shall be seised.'
            ],
            style: 'paragraph'
          },
          {
            text: '\nCHAPTER VIII FINAL PROVISION',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nArt. 8.1. ', bold: true},
              'Any modification or addition to this contract will be made on the basis of an addendum that will express the express agreement of both parties.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 8.2. ', bold: true},
              'The termination of this contract may occur with the written consent of both parties or unilaterally, in case of non-payment within the prescribed period or by the will expressed in writing by one of the parties.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 8.3. ', bold: true},
              'The provider has the right to unilaterally terminate the contract of carriage due to non-compliance/ change of data entered into the contract in ',
              {text: 'Art. 2.1.', bold: true},
              ' Therefore, the customer is obliged to pay a penalty equal to the amount of the contract of carriage within 24 hours from the date of denunciation.'
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\nArt. 8.4. ', bold: true},
              'The contract shall be deemed to be accepted by the customer, in its integrity, without the customer`s stamp and signature, provided that it has been communicated to the customer at the e-mail address indicated in the introductory part of the contract, and the customer shall not notify the cancellation of the shipment in writing after the expiry of a period of one hour following the communication.'
            ],
            style: 'paragraph'
          },
          {
            columns: [
              [{text: 'SERVICE PROVIDER'}],
              [{text: 'CUSTOMER', aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,20,0,0]
          },
          {
            columns: [
              [{text: 'SC C.M.D IRIS TRUCK SRL'}],
              [{text: this.utils.clientFormatName(client), aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: `${this.currentUser.firstName} ${this.currentUser.lastName}`.toUpperCase()}],
              [{text: client.representative, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'Transport Coordinator'}],
              [{text: client.type==='PJ'? 'Representative' : '', aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'Mobile: ' + this.currentUser.phone}],
              [{text: 'Mobile: ' + client.phone, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'E-mail: ' + this.currentUser.email}],
              [{text: 'E-mail: ' + client.email, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            image: this.utils.signature(this.currentUser.id),
            width: 150
          }    
        ],
        styles: {
          title: {
            bold: true,
            fontSize: 12,
            alignment: 'center'
          },
          paragraph: {
            fontSize: 11
          }
        }
      };

      return pdfMake.createPdf(docDefinition).download("contract_de_transport_nr_" + this.utils.getOrElse(formContent['formContractType4ContractNumber']));
    });
  }  

  contractType5RO(formContent: any, id: number) {
    this.clientsService.getClient(id).subscribe( client => {
      let docDefinition = {
        content: [
          {
            text: 'OFERTĂ DE TRANSPORT',
            style: 'title'
          },
          {
            text: '\nNr. ' + this.utils.getOrElse(formContent['formContractType5OfferNumber']) + ' din ' + this.utils.getOrElse(formContent['formContractType5OfferFrom']),
            style: 'paragraph',
            alignment: 'center'
          },
          {
            text: '\n\n\n\nPĂRȚILE CONTRACTANTE',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nSC C.M.D. IRIS TRUCK SRL', bold: true},
              ', cu sediul social în Municipiul Sibiu, Strada VASILE AARON, Nr. 22, Bloc 80, Scara E, Etaj 5, Ap. M4 (72), Județ Sibiu, Înregistrată la Registrul Comerțului sub număr J32/389/01.03.2021, având CUI RO 43821263, având cont bancar deschis la Banca ING Sibiu, reprezentată legal prin ' + `${this.currentUser.firstName} ${this.currentUser.lastName}`.toUpperCase() + ' - coordonator transport, telefon ' + this.currentUser.phone + ', e-mail ' + this.currentUser.email + ', în calitate de PRESTATOR.'],
            style: 'paragraph'
          },
          {
            text: [
              '\nȘI\n\n',
              this.utils.clientFullTextRO(client),
              {text: 'CLIENT.', bold: true}
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\n\nRută: ' + this.utils.getOrElse(formContent['formContractType5TransportRoute'])},
              {text: '\nTip marfă: ' + this.utils.getOrElse(formContent['formContractType5TransportType'])}
            ],
            style: 'paragraph',
            bold: true
          },
          {
            text: this.utils.getOrElse(formContent['formContractType5TransportUploadInfo']),
            style: 'paragraph',
            bold: true,
            margin: [ 40, 10, 0, 0 ]
          },
          {
            text: this.utils.getOrElse(formContent['formContractType5TransportDownloadInfo']),
            style: 'paragraph',
            bold: true,
            margin: [ 40, 20, 0, 15 ]
          },
          {
            text: [
              '\nPreț transport: ' + this.utils.getOrElse(formContent['formContractType5TransportTPrice']) 
            ],
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              '\n\nModalitate de plată.\n\n',
              {text: this.utils.getOrElse(formContent['formContractType5PaymentMethods']), bold: true},
            ],
            style: 'paragraph'
          },
          {
            columns: [
              [{text: 'PRESTATOR'}],
              [{text: 'CLIENT', aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,20,0,0]
          },
          {
            columns: [
              [{text: 'SC C.M.D IRIS TRUCK SRL'}],
              [{text: this.utils.clientFormatName(client), aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: `${this.currentUser.firstName} ${this.currentUser.lastName}`.toUpperCase()}],
              [{text: client.representative, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'Coordonator Transport'}],
              [{text: client.type==='PJ'? 'Reprezentant' : '', aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'Mobil: ' + this.currentUser.phone}],
              [{text: 'Mobil: ' + client.phone, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'E-mail: ' + this.currentUser.email}],
              [{text: 'E-mail: ' + client.email, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            image: this.utils.signature(this.currentUser.id),
            width: 150
          }    
        ],
        styles: {
          title: {
            bold: true,
            fontSize: 12,
            alignment: 'center'
          },
          paragraph: {
            fontSize: 11
          }
        }
      };

      return pdfMake.createPdf(docDefinition).download("oferta_de_transport_nr_" + this.utils.getOrElse(formContent['formContractType5OfferNumber']));
    });
  }

  contractType5EN(formContent: any, id: number) {
    this.clientsService.getClient(id).subscribe( client => {
      let docDefinition = {
        content: [
          {
            text: 'TRANSPORT OFFER',
            style: 'title'
          },
          {
            text: '\nNO. ' + this.utils.getOrElse(formContent['formContractType5OfferNumber']) + ' from ' + this.utils.getOrElse(formContent['formContractType5OfferFrom']),
            style: 'paragraph',
            alignment: 'center'
          },
          {
            text: '\n\n\n\nCONTRACTING PARTIES',
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              {text: '\nSC C.M.D. IRIS TRUCK SRL', bold: true},
              ', with Registered office in Sibiu, Vasile ARON Street, No. 22, Block 80, Entrance E, Floor 5, Ap. M4 (72), Sibiu County, registered at the Trade Register under number J32/389/01.03.2021, with CUI RO 43821263, with bank account opened at Banca ing Sibiu, legally represented by ' + `${this.currentUser.firstName} ${this.currentUser.lastName}`.toUpperCase() + ' - transport coordinator, phone ' + this.currentUser.phone + ', e-mail ' + this.currentUser.email + ', as a provider.'],
            style: 'paragraph'
          },
          {
            text: [
              '\nAnd\n\n',
              this.utils.clientFullTextEN(client),
              {text: 'CUSTOMER.', bold: true}
            ],
            style: 'paragraph'
          },
          {
            text: [
              {text: '\n\Route: ' + this.utils.getOrElse(formContent['formContractType5TransportRoute'])},
              {text: '\nCargo type: ' + this.utils.getOrElse(formContent['formContractType5TransportType'])}
            ],
            style: 'paragraph',
            bold: true
          },
          {
            text: this.utils.getOrElse(formContent['formContractType5TransportUploadInfo']),
            style: 'paragraph',
            bold: true,
            margin: [ 40, 10, 0, 0 ]
          },
          {
            text: this.utils.getOrElse(formContent['formContractType5TransportDownloadInfo']),
            style: 'paragraph',
            bold: true,
            margin: [ 40, 20, 0, 15 ]
          },
          {
            text: [
              '\nTransport price: ' + this.utils.getOrElse(formContent['formContractType5TransportTPrice']) 
            ],
            style: 'paragraph',
            bold: true
          },
          {
            text: [
              '\n\nPayment method. \n\n',
              {text: this.utils.getOrElse(formContent['formContractType5PaymentMethods']), bold: true},
            ],
            style: 'paragraph'
          },
          {
            columns: [
              [{text: 'SERVICE PROVIDER'}],
              [{text: 'CUSTOMER', aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,20,0,0]
          },
          {
            columns: [
              [{text: 'SC C.M.D IRIS TRUCK SRL'}],
              [{text: this.utils.clientFormatName(client), aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: `${this.currentUser.firstName} ${this.currentUser.lastName}`.toUpperCase()}],
              [{text: client.representative, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'Transport Coordinator'}],
              [{text: client.type==='PJ'? 'Representative' : '', aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'Mobile: ' + this.currentUser.phone}],
              [{text: 'Mobile: ' + client.phone, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            columns: [
              [{text: 'E-mail: ' + this.currentUser.email}],
              [{text: 'E-mail: ' + client.email, aligment: 'RIGHT', margin: [50,0,0,0]}]
            ],
            style: 'paragraph',
            bold: true,
            margin: [0,10,0,0]
          },
          {
            image: this.utils.signature(this.currentUser.id),
            width: 150
          }    
        ],
        styles: {
          title: {
            bold: true,
            fontSize: 12,
            alignment: 'center'
          },
          paragraph: {
            fontSize: 11
          }
        }
      };

      return pdfMake.createPdf(docDefinition).download("oferta_de_transport_nr_" + this.utils.getOrElse(formContent['formContractType5OfferNumber']));
    });
  }  

}
