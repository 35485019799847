<!-- Navigation-->
<nav class="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">
    <div class="container">
        <a class="navbar-brand js-scroll-trigger" routerLink="/invoices"><span>CMD Truck - Facturare</span><img src='../../assets/images/logo.png' style="display: none;"/></a>
        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
        <div class="collapse navbar-collapse" id="navbarResponsive" *ngIf="currentUser">
            <ul class="navbar-nav ml-auto my-2 my-lg-0">
                <li class="nav-item"><a class="nav-link js-scroll-trigger" routerLink="/invoices">Facturi</a></li>
                <li class="nav-item"><a class="nav-link js-scroll-trigger" routerLink="/history">Istoric</a></li>
                <li class="nav-item"><a class="nav-link js-scroll-trigger" (click)="logout()"><i class="fas fa-sign-out-alt"></i> Deconectare</a></li>
            </ul>
        </div>
    </div>
</nav>